import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { ProductFilter, ProductExtraData } from '../interfaces/product.interface';

export type FilterProductType = 'brand' | 'category' | 'size' | 'condition' | 'colors' | 'text';

@Injectable({
  providedIn: 'root'
})
export class FilterProductService implements OnDestroy {

  private filtersSub: BehaviorSubject<ProductFilter> = new BehaviorSubject({});
  public filters$: Observable<ProductFilter> = this.filtersSub.asObservable();

  private tagFilter: boolean = false;
  public tagName: string = '';

  constructor(
      private http: HttpClient
  ) { }

  ngOnDestroy(): void {
      this.filtersSub.complete();
  }

  getCurrentFilters() {
      return this.filtersSub.value;
  }

  public isTagFiltered (){
      return this.tagFilter;
  }

  getItems(filter: FilterProductType, text?: string): Observable<ProductExtraData[]> {
      switch (filter) {
          case 'brand':
              return this.getItemsBrand(text);
          case 'size':
              return this.getItemsSize();
          case 'category':
              return this.getItemsCategory();
          case 'colors':
              return this.getItemsColor();
          case 'condition':
              return this.getItemsCondition();
      }
  }

  setFilter(filter: FilterProductType, valueFilter: object) {
      const keyFilter = filter + 'Filter';
      const filters = this.filtersSub.value;
      filters[keyFilter] = valueFilter;
      this.filtersSub.next(filters);
  }

  setAllFilters(filters: ProductFilter, tagFilter: boolean, tagNameView?: string) {
    if(tagNameView)  {
        this.tagName = '#' + tagNameView;
    } else {
        this.tagName='';
    }
    this.tagFilter = tagFilter;   
    this.filtersSub.next(filters);
  }

  resetFilters() {
      this.tagFilter=false;
      this.tagName='';
      this.filtersSub.next({});
  }

  getItemsCategoryByParent(parent?: any): Observable<ProductExtraData[]> {
    let url = ENV.baseUrl + ENV.api.products + '/categories';
    if (parent) {
        url += '?parent=' + parent;
    }
    return this.http.get<ProductExtraData[]>(url);
  }

  private getItemsBrand(text: string): Observable<ProductExtraData[]> {
      const url = ENV.baseUrl + ENV.api.products + '/brands?name=' + encodeURIComponent(text);
      return this.http.get<ProductExtraData[]>(url);
  }
  private getItemsSize(): Observable<ProductExtraData[]> {
      const url = ENV.baseUrl + ENV.api.products + '/sizes';
      return this.http.get<ProductExtraData[]>(url);
  }
  private getItemsCategory(): Observable<ProductExtraData[]> {
      const url = ENV.baseUrl + ENV.api.products + '/categories?parent=all';
      return this.http.get<ProductExtraData[]>(url);
  }
  private getItemsColor(): Observable<ProductExtraData[]> {
      const url = ENV.baseUrl + ENV.api.products + '/colors';
      return this.http.get<ProductExtraData[]>(url);
  }
  private getItemsCondition(): Observable<ProductExtraData[]> {
      const url = ENV.baseUrl + ENV.api.products + '/conditions';
      return this.http.get<ProductExtraData[]>(url);
  }
}
