import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from './date-ago.pipe';
import { StatusPipe } from './status.pipe';
import { CounterPipe } from './counter.pipe';
import { SanitizeHtmlPipe } from './sanitize.pipe';

@NgModule({
  declarations: [DateAgoPipe, StatusPipe, CounterPipe, SanitizeHtmlPipe],
  imports: [
    CommonModule
  ],
  exports: [DateAgoPipe, StatusPipe, CounterPipe, SanitizeHtmlPipe]
})
export class MyPipesModule { }
