
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notificaciones-button',
  templateUrl: './notificaciones-button.component.html',
  styleUrls: ['./notificaciones-button.component.scss'],
})
export class NotificacionesButtonComponent implements OnInit, OnDestroy{

  @Input() type: string;
  count = 0;
  countSubscription: Subscription;

  constructor(
    public notSvc: NotificationsService
  ) { }

  ngOnInit() {
    this.countSubscription = this.notSvc.count$.subscribe(
      count => {
        this.count = count;
      }
      ,
      e => {
        console.log(e);
      }
    );
  }

  ngOnDestroy(): void {
    this.countSubscription.unsubscribe();
  }

}
