import { Component, ViewChild, OnInit } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { FilterProductComponent } from './filter-product/filter-product.component';
import { FilterUserComponent } from './filter-user/filter-user.component';
import { FilterService } from '../../../services/filter.service';
import { UserService } from '../../../services/user.service';
import { FilterProductService } from '../../../services/filter-product.service';
import { ProductFilter } from '../../../interfaces/product.interface';

@Component({
  selector: 'app-modal-filter',
  templateUrl: './modal-filter.page.html',
  styleUrls: ['./modal-filter.page.scss'],
})
export class ModalFilterPage implements OnInit {

  @ViewChild('filterProduct', { static: false }) filterProduct: FilterProductComponent;
  @ViewChild('filterUser', { static: false }) filterUser: FilterUserComponent;

  valueSegment: 'products' | 'users' = 'products';
  initialSlide = 0;
  readonly PRODUCT_INDEX = 0;
  readonly USER_INDEX = 1;

  disabledClearFiltersBtn = true;

  constructor(
    private modalCtrl: ModalController,
    private filterSvc: FilterService,
    private userSvc: UserService,
    private filtersProdSvc: FilterProductService
  ) { }

  ngOnInit() {}

  get isFiltered() {
    let filtered = false;
    try {
      filtered = Boolean((this.initialSlide === this.USER_INDEX && this.isUserFiltered()) ||
      (this.initialSlide === this.PRODUCT_INDEX && this.isProductFiltered()));
    } catch (error) {
      console.log('error check filter');
    }
    return filtered;
  }

  closeModal(result?: { data, role }) {
    if (!result) {
      this.modalCtrl.dismiss();
    } else {
      this.filterSvc.launchFilter(result.data, result.role);
      this.modalCtrl.dismiss();
    }
  }

  onResetFilters() {
    if (this.initialSlide === this.USER_INDEX) {
      this.filterUser.resetFilters();
    } else if (this.initialSlide === this.PRODUCT_INDEX) {
      this.filterProduct.resetFilters();
    }
  }

  segmentChanged(event: any) {
    const detail = event.detail;
    if (detail) {
      this.valueSegment = detail.value;
      if (detail.value === 'users') {
        this.initialSlide = this.USER_INDEX;
      } else if (detail.value === 'products') {
        this.initialSlide = this.PRODUCT_INDEX;
      }
    }
  }

  private isUserFiltered(): boolean {
    return Boolean(this.userSvc.userFilter && this.userSvc.userFilter.usernameFilter);
  }

  private isProductFiltered(): boolean {
    const filtersProducts: ProductFilter = this.filtersProdSvc.getCurrentFilters();
    const countFilters = Object.keys(filtersProducts).length;
    return Boolean(countFilters);
  }
}
