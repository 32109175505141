import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'recuperar-password',
    loadChildren: () => import('./pages/remind-password/remind-password.module').then( m => m.RemindPasswordPageModule)
  },
  {
    path: 'set-password/:token',
    loadChildren: () => import('./pages/set-password/set-password.module').then( m => m.SetPasswordPageModule)
  },
  {
    path: 'micarrito',
    loadChildren: () => import('./pages/detail-my-cart/detail-my-cart.module').then( m => m.DetailMyCartPageModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs/faqs.module').then( m => m.FAQsPageModule)
  },
  {
    path: 'payment', 
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule),
      }
    ]
  },
  // tab 1 - escaparate
  {
    path: 'escaparate',
    children: [
          {
            path: '',
            loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
          },
          {
            path: 'productos/:slug',
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/detail-product/detail-product.module').then( m => m.DetailProductPageModule)
              },
              {
                path: 'editar',
                loadChildren: () => import('./pages/edit-product/edit-product.module').then( m => m.EditProductPageModule),
              }
            ]
          },
          {
            path: 'usuarios/:username',
            loadChildren: () => import('./pages/detail-user/detail-user.module').then( m => m.DetailUserPageModule)
          }
    ]
  },
  // tab 2 - favoritos
  {
    path: 'favoritos',
    loadChildren: () => import('./pages/favourites/favourites.module').then( m => m.FavouritesPageModule),
  },
  // tab 3 - nuevo-producto
  {
    path: 'subirproducto',
    loadChildren: () => import('./pages/new-product/new-product.module').then( m => m.NewProductPageModule),
  },
  // tab 4 - notificaciones
  {
    path: 'notificaciones',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule),
  },
  // tab 5 - perfil
  {
    path: 'perfil',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/detail-user/detail-user.module').then( m => m.DetailUserPageModule)
      },
      {
        path: 'configuracion',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
          },
          {
            path: 'editar',
            loadChildren: () => import('./pages/edit-my-profile/edit-my-profile.module').then( m => m.EditMyProfilePageModule)
          },
          {
            path: 'usuarias-bloqueadas',
            loadChildren: () => import('./pages/blocked-users/blocked-users.module').then( m => m.BlockedUsersPageModule)
          },
          {
            path: 'compras',
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/purchases/purchases.module').then( m => m.PurchasesPageModule)
              },
              {
                path: ':id',
                loadChildren: () => import('./pages/detail-purchase/detail-purchase.module').then( m => m.DetailPurchasePageModule)
              }
            ]
          },
          // {
          //   path: 'ventas',
          //   children: [
          //     {
          //       path: '',
          //       loadChildren: () => import('./pages/sales/sales.module').then( m => m.SalesPageModule)
          //     },
          //     {
          //       path: ':id',
          //       loadChildren: () => import('./pages/detail-sale/detail-sale.module').then( m => m.DetailSalePageModule)
          //     }
          //   ]
          // },
          {
            path: 'ventas',
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/suborder/suborder.module').then( m => m.SuborderPageModule)
              },
              {
                path: ':id',
                loadChildren: () => import('./pages/detail-sale-by-suborder/detail-sale-by-suborder.module').then( m => m.DetailSaleBySuborderPageModule)
              }
            ]
          },
          {
            path: 'faqs',
            loadChildren: () => import('./pages/faqs/faqs.module').then( m => m.FAQsPageModule)
          },
          {
            path: 'cupones',
            loadChildren: () => import('./pages/coupons/coupons.module').then( m => m.CouponsPageModule)
            // children: [
            //   {
            //     path: '',
            //     loadChildren: () => import('./pages/coupons/coupons.module').then( m => m.CouponsPageModule)
            //   },
            //   {
            //     path: 'newCoupon',
            //     loadChildren: () => import('./pages/coupons/new-coupon/new-coupon.module').then( m => m.NewCouponPageModule)
            //   }
            // ]
          }
        ]
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: '/escaparate',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/escaparate'
  },
  {
    path: 'coupons',
    loadChildren: () => import('./pages/coupons/coupons.module').then( m => m.CouponsPageModule)
  },
  // {
  //   path: 'suborder',
  //   loadChildren: () => import('./pages/suborder/suborder.module').then( m => m.SuborderPageModule)
  // },
  // {
  //   path: 'detail-sale-by-suborder',
  //   loadChildren: () => import('./pages/detail-sale-by-suborder/detail-sale-by-suborder.module').then( m => m.DetailSaleBySuborderPageModule)
  // }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
