import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-web-upload-product',
  templateUrl: './web-upload-product.component.html',
  styleUrls: ['./web-upload-product.component.scss'],
})
export class WebUploadProductComponent implements OnInit {

  constructor(
    private navCtrl: NavController
  ) { }

  ngOnInit() {}

  goToUploadProduct() {
    const url = '/subirproducto';
    this.navCtrl.navigateForward(url);
  }

}
