import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-report-popover',
  templateUrl: './report-popover.component.html',
  styleUrls: ['./report-popover.component.scss'],
})
export class ReportPopoverComponent implements OnInit {

  @Input() showReport = true;
  @Input() block = false;
  showConfirm = false;

  constructor(
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {}

  action(type: 'block' | 'report') {
    this.popoverCtrl.dismiss({ type });
  }

  confirmReport() {
    this.showConfirm = true;
  }

  cancel() {
    this.popoverCtrl.dismiss();
  }
}
