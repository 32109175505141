import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterProductService } from './filter-product.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private changeFiltersSub: BehaviorSubject<{ data?: any, role?: 'product' | 'user' }> = new BehaviorSubject(null);
  public changeFilters$: Observable<{ data?: any, role?: 'product' | 'user' }> = this.changeFiltersSub.asObservable();

  constructor(
    private prodFilterSrv: FilterProductService,
    private userSrv: UserService
  ) { }

  public launchFilter(data, role) {
    this.changeFiltersSub.next({ data, role });
  }
  public clearAndLoadItems() {
    this.prodFilterSrv.resetFilters();
    this.userSrv.resetFilters(); 
    this.changeFiltersSub.next({data:null, role: null});
  }
}
