import { Injectable } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor,
  StoragePlugin
} from '@capacitor/core';
import { Router } from '@angular/router';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { NotificationsService } from './notifications.service';

const { PushNotifications, Storage, Device } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  private USER_DEVICE_TOKEN_DB = 'mobileDeviceTokenPushNotification';
  private storage: StoragePlugin;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastCtrl: ToastController,
    private notSvc: NotificationsService
  ) {
    this.storage = Storage;
  }

  public initPush() {
    if (Capacitor.platform !== 'web') {
      this.registerMobilePush();
    } else {
      //...
    }
  }

  public async disablePush() {
    return new Promise((resolve, reject) => {
      // Check platform
      Device.getInfo().then(info => {
        console.log('DEVICE INFO', info);
        
        if (info.platform === 'android' || info.platform === 'ios') {
          // Comprobar que existe token de dispositivo almacenado
          this.getDeviceToken().then(token => {
            if (token) {
              this.disableMobileDeviceToken({token}).subscribe(res => {
                console.log('RESPUESTA DESHABILITAR DISPOSITIVO --> ', res);
                this.deleteDeviceToken()
                  .then(() => {
                    console.log('ELIMINADO EL DEVICE_TOKEN DEL STORAGE');
                    return resolve(true);
                  })
                  .catch(error => {
                    console.log('ERROR ELIMINANDO EL DEVICE_TOKEN DEL STORAGE --> ', error);
                    return reject(false);
                  });
              });
            } else {
              return reject(false);
            }
          })
          .catch(error => {
            console.log('ERROR RECOGIENDO DEVICE_TOKEN DEL STORAGE --> ', error);
          });
        } else {
          // Devuelve OK porque al no estar en "android"/"ios" no necesita dar de baja el token del dispositivo
          return resolve(true);
        }
      });
    });
  }

  private registerMobilePush() {
    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted...
      }
    });

    PushNotifications.addListener('registration', (deviceToken: PushNotificationToken) => {
      console.log('My token: ' + JSON.stringify(deviceToken));
      let token = deviceToken.value;
      console.log('EL TOKEN DEVICE --> ', token);

      this.getDeviceToken().then(previousTokenSaved => {
        if (previousTokenSaved && previousTokenSaved != token) {
          this.deleteDeviceToken().then(() => {
            console.log('ELIMINADO ANTERIOR TOKEN!');
          })
          .catch(error => {
            console.log('ERROR ELIMINANDO DEVICE_TOKEN ANTIGUO! --> ', error);
          });
        }

        // Registrar token de dispositivo en Base de Datos (BACK)
        this.registerMobileDeviceToken({token}).subscribe(res => {
          console.log('RESPUESTA REGISTRO DEVICE --> ', res);
          // Almacenar el token del dispositivo en storage local si el registro en BACK es correcto
          this.saveDeviceToken(this.USER_DEVICE_TOKEN_DB, token);
        });
      })
      .catch(error => {
        console.log('ERROR RECOGIENDO DEVICE_TOKEN ANTIGUO! --> ', error);
      });
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', async(notification: PushNotification) => {
      console.log('Push received: ' + JSON.stringify(notification));
      let notificationType = notification.data.type ? notification.data.type : 0; // Si no hay tipo de notificación, default 0
      console.log('TIPO DE NOTIFICACIÓN --> ', notificationType);
      
      // Switch cases NOTIFICACIONES
      let message = '';
      switch(Number(notificationType)) {
        case 1: // NUEVA VENTA
          message = '¡Has vendido un producto!';
          break;
        
        case 2: // NUEVO COMENTARIO
          message = '¡Un usuario ha comentado en tu prenda! 📝';
          break;
        
        case 3: // NUEVO ME GUSTA
          message = '¡A un usuario le ha gustado tu producto! ❤';
          break;
        
        case 4: // NUEVO SEGUIDOR
          message = '¡Un usuario ha comenzado a seguirte! 👀';
          break;

        case 5: // NUEVO FAVORITO
          message = '¡Un usuario ha guardado tu prenda como favorita! ✔';
          break;
        
        case 6:
          message = '¡Te han comprado un producto en depósito!';
          break;

        default:
          message = '¡Tienes una nueva notificación!';
          break;
      }

      this.presentToast(message, 'primary');

      this.notSvc.getUnreadNotifications().subscribe(
        (sumNotifShip) => {
          this.notSvc.updateCount(sumNotifShip);
        },
        (e)=> console.log(e)
      );

      // PushNotifications.listChannels()
      //   .then(channels => {
      //     console.log('CANALES --> ', channels);
      //   })
      //   .catch(error => {
      //     console.log('ERROR LISTANDO CANALES --> ', error);
      //   });
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async(notification: PushNotificationActionPerformed) => {
      // const data = notification.notification.data;
      console.log('Action performed: ' + JSON.stringify(notification));
      // if (data.detailsId) {
      //   this.router.navigateByUrl(`/home/${data.detailsId}`);
      // }
      this.router.navigate(['/notificaciones']);
    });
  }

  registerMobileDeviceToken(body: { token: string }): Observable<any> {
    const url = ENV.baseUrl + ENV.api.notifications + '/new-device';
    return this.http.post<any>(url, body);
  }

  disableMobileDeviceToken(body: { token: string }): Observable<any> {
    const url = ENV.baseUrl + ENV.api.notifications + '/disable-device';
    return this.http.post<any>(url, body);
  }

  private async presentToast(message, color: 'primary' | 'warning' = 'primary', duration = 2000) {
    const toast = await this.toastCtrl.create({
        message,
        duration,
        color,
        position: 'middle'
    });
    toast.present();
  }

  private saveDeviceToken(key: string, token: string) {
    this.storage.set({ key, value: token })
      .then(() => {
        console.log(`Seteado STORAGE KEY:${key} VALUE: ${token}`);
      }).catch(e => {
          console.log(`Set STORAGE KEY:${key} ERROR: ${e}`);
      });
  }

  getDeviceToken(): Promise<string> {
    return this.storage.get({ key: this.USER_DEVICE_TOKEN_DB }).then(res => res.value );
  }

  deleteDeviceToken(): Promise<void> {
    return this.storage.remove({ key: this.USER_DEVICE_TOKEN_DB });
  }

}
