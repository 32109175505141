import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {

  homeIcon = './assets/img/footer/home.svg';
  favIcon = './assets/img/footer/favorito.svg';
  newIcon = './assets/img/footer/subir.svg';
  alertIcon = './assets/img/footer/notificaciones.svg';
  profileIcon = './assets/img/footer/perfil.svg';

  constructor() { }

  ngOnInit() {}

}
