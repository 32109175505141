import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-menu-popover',
  templateUrl: './menu-popover.component.html',
  styleUrls: ['./menu-popover.component.scss'],
})
export class MenuPopoverComponent implements OnInit {

  constructor(
    private navCtrl: NavController,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {}

  search() {
    this.popoverCtrl.dismiss({ search: true });
  }

  goToPage(page: string) {
    this.navCtrl.navigateRoot('/' + page, { animated: true });
    this.popoverCtrl.dismiss();
  }

}
