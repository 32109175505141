import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {
  private statuses = {
    purchase: [
      undefined,
      undefined,
      {
        text: 'pagado',
        class: 'glamyGood'
      },
      undefined,
      {
        text: 'incidencia',
        class: 'glamyWarning'
      },
      {
        text: 'reembolsado',
        class: 'glamyBad'
      }
    ],
    sale: [
      undefined,
      {
        text: 'pendiente',
        class: 'glamyWarning'
      },
      {
        text: 'prerregistrado',
        class: 'glamyWarning'
      },
      {
        text: 'en camino',
        class: 'glamyGood'
      },
      {
        text: 'entregado',
        class: 'glamyGood'
      },
      {
        text: 'revisando prenda',
        class: 'glamyWarning'
      },
      {
        text: 'revisando prenda',
        class: 'glamyWarning'
      }
    ],
    itemSale: [
      undefined,
      {
        text: 'pendiente',
        class: 'glamyWarning'
      },
      {
        text: 'prerregistrado',
        class: 'glamyWarning'
      },
      {
        text: 'en camino',
        class: 'glamyGood'
      },
      {
        text: 'entregado',
        class: 'glamyGood'
      },
      {
        text: 'revisando prenda',
        class: 'glamyWarning'
      },
      {
        text: 'revisando prenda',
        class: 'glamyWarning'
      },
      {
        text: 'incidentado',
        class: 'glamyBad'
      }
    ],
    itemSalePremier: [
      undefined,
      {
        text: 'pendiente',
        class: 'glamyWarning'
      },
      {
        text: 'prerregistrado',
        class: 'glamyWarning'
      },
      {
        text: 'en camino',
        class: 'glamyGood'
      },
      {
        text: 'entregado',
        class: 'glamyGood'
      },
      {
        text: 'revisando prenda',
        class: 'glamyWarning'
      },
      {
        text: 'revisando prenda',
        class: 'glamyWarning'
      },
      {
        text: 'incidentado',
        class: 'glamyBad'
      },
      {
        text: 'gestionando',
        class: 'glamyWarning'
      },
      {
        text: 'esperando prenda',
        class: 'glamyWarning'
      },
      {
        text: 'revisando prenda',
        class: 'glamyWarning'
      }
    ]
  };

  constructor(
    private sanitized: DomSanitizer
  ) {}

  transform(value: any, type: 'purchase' | 'sale' | 'itemSale' | 'itemSalePremier'): any {
    if ((value || value === 0) && type) {
      try {
        const currentStatus = this.statuses[type][value];
        const statusClass = currentStatus.class;
        const statusText = currentStatus.text;
        const resHtml = `<span class="${statusClass}">${statusText}</span>`;
        value = this.sanitized.bypassSecurityTrustHtml(resHtml);
      } catch (error) {
        console.log('Error status pipe: ', error);
        value = '';
      }
    } else {
      value = '';
    }

    return value;
  }

}
