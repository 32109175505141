import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {

  transform(value: any, short: boolean = false, ...args: any[]): any {
    if (value) {
      const now = +new Date();
      const eventDate = +new Date(value);
      const differenceInSeconds = Math.floor((now - eventDate) / 1000);

      const timeIntervals = {
        year: { seconds: 31536000, singular: 'año', plural: 'años' },
        month: { seconds: 2592000, singular: 'mes', plural: 'meses' },
        week: { seconds: 604800, singular: 'semana', plural: 'semanas', short: 'sem' },
        day: { seconds: 86400, singular: 'día', plural: 'días', short: 'd' },
        hour: { seconds: 3600, singular: 'hora', plural: 'horas', short: 'h' },
        minute: { seconds: 60, singular: 'minuto', plural: 'minutos', short: 'min' },
        second: { seconds: 1, singular: 'segundo', plural: 'segundos', short: 'seg' }
      };

      if (differenceInSeconds <= 0) {
        return 'Ahora mismo';
      }

      let counter;
      for (const key in timeIntervals) {
        if (timeIntervals.hasOwnProperty(key)) {
          counter = Math.floor(differenceInSeconds / timeIntervals[key].seconds);
          if (counter > 0) {
            if (short && timeIntervals[key].short) {
              return counter + ' ' + timeIntervals[key].short;
            } else  if (counter === 1) {
              return counter + ' ' + timeIntervals[key].singular;
            } else {
              return counter + ' ' + timeIntervals[key].plural;
            }
          }
        }
      }

    }

    return value;
  }

}
