import { Product } from "./product.interface";

export class ShippingItem {
  public static readonly SENDING_PRICE = 4.95;
  public static readonly EXTRA_EXPRESS_PRICE = 1.99;

  public static readonly EXTRA_PREMIER_PRICE = 4.95;

  public static readonly PRODUCT_STATUS_ACTIVO = 1;

  public items: CartItem[] = [];

  public fromCanary = false;

  public onDeposit = false;

  constructor(
    private owner?: ShippingSeller,
    public express: boolean = false
  ) {}

  static fromDB(obj: any): ShippingItem {
    const shippingItem = new ShippingItem();
    const owner: ShippingSeller = {};
    const onDeposit = obj.on_deposit || false;
    if (onDeposit) {
      owner.id = -1;
      owner.username = "Depósito";
    } else {
      if (obj.user) {
        owner.id = obj.user.id;
        owner.username = obj.user.username;
      }
    }

    const items: CartItem[] = [];
    const item: CartItem = {};
    item.id = obj.id;
    item.main_image = obj.main_image;
    item.price = Number(obj.sale_price);
    item.status_id = obj.status_id;
    item.title = obj.title;
    item.sellerName = obj.user.username;
    item.slug = obj.slug;
    items.push(item);

    shippingItem.fromCanary = obj.user.isCanary || false;
    shippingItem.owner = owner;
    shippingItem.onDeposit = onDeposit;
    shippingItem.items = items;

    return shippingItem;
  }

  static fromObj(obj: any): ShippingItem {
    const item = new ShippingItem();
    const owner: ShippingSeller = {};
    if (obj.owner) {
      owner.id = obj.owner.id;
      owner.username = obj.owner.username;
      owner.avatar = obj.owner.avatar;
    }
    const onDeposit = obj.onDeposit || false;
    const express = obj.express || false;
    const items: CartItem[] = [];
    if (obj.items && obj.items.length > 0) {
      obj.items.map((i) => {
        const ci: CartItem = {};
        ci.id = i.id;
        ci.main_image = i.main_image;
        ci.price = Number(i.price);
        ci.status_id = i.status_id;
        ci.title = i.title;
        ci.sellerName = i.sellerName;
        ci.slug = i.slug;
        items.push(ci);
      });
    }
    item.fromCanary = obj.fromCanary || false;
    item.owner = owner;
    item.express = express;
    item.onDeposit = onDeposit;
    item.items = items;
    return item;
  }

  get ownerUsername(): string {
    let username;
    if (this.owner) {
      username = this.owner.username;
    }
    return username;
  }

  get ownerId(): any {
    let id;
    if (this.owner) {
      id = this.owner.id;
    }
    return id;
  }

  get countItems(): number {
    return this.items.length;
  }

  getTotalPrice(premier: boolean = false): number {
    let total;
    if (this.items.length > 0) {
      total = 0;
      for (const p of this.items) {
        p.status_id === ShippingItem.PRODUCT_STATUS_ACTIVO
          ? (total += p.price)
          : total;
      }

      if (
        this.items.some(
          (item) => item.status_id === ShippingItem.PRODUCT_STATUS_ACTIVO
        )
      ) {
        total += ShippingItem.SENDING_PRICE;
        if (this.express && !premier) {
          total += ShippingItem.EXTRA_EXPRESS_PRICE;
        }
      }
    }
    return total;
  }

  addItem(product: Product): boolean {
    let added = false;
    if (product.on_deposit) {
      this.onDeposit = true;
    } else if (
      !product.user ||
      !product.user.id ||
      product.user.id !== this.owner.id
    ) {
      throw new Error(
        "no coincide el propietario del producto con el propietario en el shipping"
      );
    }
    const i = this.getItemIndex(product.id);
    if (i < 0) {
      const cartItem = {
        id: product.id,
        title: product.title,
        price: Number(product.sale_price),
        status_id: product.status_id,
        sellerName: product.user?.username,
        main_image: product.main_image?.url_small,
        slug: product.slug,
      };
      this.items.push(cartItem);
      added = true;
    }
    return added;
  }

  removeItem(idProduct: any): boolean {
    let removed = false;
    const i = this.getItemIndex(idProduct);
    if (i > -1) {
      this.items.splice(i, 1);
      removed = true;
    }
    return removed;
  }

  private getItemIndex(idItem: any): number {
    const index = this.items.findIndex((item) => item.id === idItem);
    return index;
  }
}

export interface ShippingSeller {
  id?: any;
  username?: any;
  avatar?: any;
}

export interface CartItem {
  id?: any;
  title?: string;
  main_image?: string;
  price?: number;
  status_id?: any;
  sellerName?: string;
  slug?: string;
}
