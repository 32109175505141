import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AuthenticationService } from './services/authentication.service';
import {
  Plugins,
  StatusBarStyle,
  StatusBarPlugin,
  Capacitor,
} from '@capacitor/core';
import { PreviousRouteService } from './services/previous-route.service';
import { Router } from '@angular/router';
import { NotificationsService } from './services/notifications.service';
import { MyCartService } from './services/my-cart.service';
import { TranslateService } from '@ngx-translate/core';
const { StatusBar } = Plugins;
const { App } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private statusBar: StatusBarPlugin;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private authService: AuthenticationService,
    private previousRouteService: PreviousRouteService,
    private router: Router,
    private notSvc: NotificationsService,
    private cartSvc: MyCartService,
    private zone: NgZone,
    private translate: TranslateService
  ) {
     // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('es');

     // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('es');

    this.statusBar = StatusBar;
    this.initializeApp();
    this.authService.userLoggedWithValidToken('token').then(logged => {
      if (logged){
        this.cartSvc.loadCartFromDB();
      }else {
        this.cartSvc.loadCartFromStorage();
      }
    })
    
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.platform.backButton.unsubscribe();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // DEEPLINKS
      App.addListener('appUrlOpen', (data: any) => {
        this.zone.run(() => {
            // Example url: https://glamy.es/escaparate/products/camiseta-glamy
            // slug = /escaparate/products/camiseta-glamy
            const slug = data.url.split("glamy.es").pop();
            if (slug) {
                this.router.navigateByUrl(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    });

      if (Capacitor.isPluginAvailable('StatusBar')) {
        this.statusBar.setStyle({ style: StatusBarStyle.Dark });
      }

      // Check si existe "accessToken" para hacer petición a "total notificaciones"
      this.authService.getToken('token')
      .then(token => {
          // Si hay token, entonces solicitar número de notificaciones a mostrar
          if (token) {
              this.notSvc.getUnreadNotifications().subscribe(
                  (sumNotifShip) => {
                      this.notSvc.updateCount(sumNotifShip);
                      this.splashScreen.hide();
                  },
                  (error)=> {
                      console.log('ERROR GET TOTAL NOTIFICACIONES --> ', error);
                      this.splashScreen.hide();
                  }
              );
          } else {
            // ...
            this.splashScreen.hide();
          }
      })
      .catch(error => {
          console.log('ERROR NO EXISTE TOKEN PARA RECOGER TOTAL NOTIF --> ', error);
          this.splashScreen.hide();
      });
      
      // this.splashScreen.hide();
      this.registerBackButton();
    });
  }

  private registerBackButton() {
    this.platform.backButton.subscribeWithPriority(10, () => {
      console.log('Handler was called!');
      // this.navCtrl.back();
      // this.location.back();
      const prevUrl = this.previousRouteService.getPreviousUrl();
      if (prevUrl) {
        this.router.navigate([prevUrl]);
      } else {
        this.router.navigate(['/']);
      }
    });
  }
}
