import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { User, UserItem, UserFilter } from '../interfaces/user.interface';
import { environment as ENV } from '../../environments/environment';
import { Address } from '../interfaces/address.interface';
import { Follower, FollowerJSON, FollowerList } from '../interfaces/follower.interface';
import { map, pluck, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userFilter: UserFilter = {};
  followers : Follower[] = [];

  constructor(
    private http: HttpClient
  ) { }

  //TODO: Este método no deberia estar aqui.
  public resetFilters(): void {
    this.userFilter = {};
  }

  getUser(username: string): Observable<User> {
    const url = ENV.baseUrl + ENV.api.users + '/user/' + encodeURIComponent(username);
    return this.http.get<User>(url);
  }

  getMyProfile(): Observable<User> {
    const url = ENV.baseUrl + ENV.api.users + '/profile';
    return this.http.get<User>(url);
  }

  getMyProfileToEdit(): Observable<User> {
    const url = ENV.baseUrl + ENV.api.users + '/profile/edit';
    return this.http.get<User>(url);
  }

  getUsers(
    pageIndex: number = 1,
    pageSize: number = 25,
    sortField: string = 'created_at',
    sortOrder: 'asc' | 'desc' = 'desc'
  ): Observable<HttpResponse<UserItem[]>> {

    const url = ENV.baseUrl + ENV.api.users;

    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('limit', `${pageSize}`)
      .append('orderBy', `${sortField}`)
      .append('orderType', `${sortOrder}`);

    if (this.userFilter) {
      const filtersKeys = Object.keys(this.userFilter);
      filtersKeys.forEach(key => {
       if (key === 'usernameFilter'){
          // params = params.append(key, encodeURIComponent(this.userFilter[key])); // No es necesario hacer "encodeURI" si se añade como HttpParams
          params = params.append(key, this.userFilter[key]);
        } else {
          params = params.append(key, this.userFilter[key]);
        }
      });
    }

    return this.http.get<UserItem[]>(url, { params, observe: 'response' });
  }

  getUserAddress(): Observable<Address[]> {
    const url = ENV.baseUrl + ENV.api.users + '/address';
    return this.http.get<Address[]>(url);
  }

  setUserAddress(newAddress: Address): Observable<any> {
    const address = newAddress.address;
    const city = newAddress.city;
    const province = newAddress.province;
    const country = 'ES';
    const postal_code = newAddress.postal_code;
    const phone = newAddress.phone;
    const contact = newAddress.contact;

    const body = {
      address,
      city,
      province,
      country,
      postal_code,
      phone,
      contact
    };

    const url = ENV.baseUrl + ENV.api.users + '/address/new';
    return this.http.post<any>(url, body);
  }

  getFollowers (userId: any, pageIndex: number = 1,
    pageSize: number = 25,
    sortField: string = 'created_at',
    sortOrder: 'asc' | 'desc' = 'desc'): Observable<FollowerList>{

    const url = ENV.baseUrl + ENV.api.users + '/' + userId + '/followers';

    let params = new HttpParams()
    .append('page', `${pageIndex}`)
    .append('limit', `${pageSize}`)
    .append('orderBy', `${sortField}`)
    .append('orderType', `${sortOrder}`);

    return this.http
    .get<HttpResponse<FollowerJSON[]>>(url, { params , observe: 'response'})
    .pipe(
      map((response) => {
        let followers : Follower[] = [];
        let followersJSON : FollowerJSON[] = response.body['followers'];
        followersJSON.forEach(followerJSON => {
          followers.push(new Follower(followerJSON));
        }
      )
   
      const totalFollowers = Number(response.headers.get('X-Total-Count'));  
      let lastPage = (totalFollowers == (((pageIndex-1)*pageSize) + followers.length)) ? true : false;
    
      let followerList : FollowerList = new FollowerList(followers, lastPage);
      return followerList;
      })
    )
                                    
  }

  getFollows (userId: any,
    pageIndex: number = 1,
    pageSize: number = 25,
    sortField: string = 'created_at',
    sortOrder: 'asc' | 'desc' = 'desc',
    ): Observable<FollowerList>{

    const url = ENV.baseUrl + ENV.api.users + '/' + userId + '/follows';

    let params = new HttpParams()
    .append('page', `${pageIndex}`)
    .append('limit', `${pageSize}`)
    .append('orderBy', `${sortField}`)
    .append('orderType', `${sortOrder}`);
                                      
    return this.http
    .get<HttpResponse<FollowerJSON[]>>(url, { params , observe: 'response'})
    .pipe(
      map((response)=>{
        let follows : Follower[] = [];
        let followsJSON : FollowerJSON[] = response.body['follows'];
        followsJSON.forEach(followerJSON => {
          follows.push(new Follower(followerJSON));
        }
      )
   
      const totalFollowers = Number(response.headers.get('X-Total-Count'));  
      let lastPage = (totalFollowers == (((pageIndex-1)*pageSize) + follows.length)) ? true : false;

      let followerList : FollowerList = new FollowerList(follows, lastPage);
      return followerList;
      })
    )
    
  }
  
}
