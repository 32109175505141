import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { AccordionComponent } from './accordion/accordion.component';
import { MyPipesModule } from '../pipes/my-pipes.module';
import { ItemAccordionComponent } from './item-accordion/item-accordion.component';
import { HeaderHomeComponent } from './header-home/header-home.component';
import { BagButtonComponent } from './bag-button/bag-button.component';
import { AddressInputComponent } from './address-input/address-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditIbanComponent } from './edit-iban/edit-iban.component';
import { TabsComponent } from './tabs/tabs.component';
import { WebUploadProductComponent } from './web-upload-product/web-upload-product.component';
import { MenuPopoverComponent } from './header-home/menu-popover/menu-popover.component';
import { ReportPopoverComponent } from './report-popover/report-popover.component';
import { ConditionsModalComponent } from './conditions-modal/conditions-modal.component';
import { AddressInputDumbComponent } from './address-input-dumb/address-input-dumb.component';
import { NotificacionesButtonComponent } from './notificaciones-button/notificaciones-button.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ValidCouponComponent } from '../pages/detail-my-cart/valid-coupon/valid-coupon.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderHomeComponent,
    AccordionComponent,
    AddressInputComponent,
    AddressInputDumbComponent,
    EditIbanComponent,
    TabsComponent,
    ItemAccordionComponent,
    BagButtonComponent,
    NotificacionesButtonComponent,
    WebUploadProductComponent,
    MenuPopoverComponent,
    ReportPopoverComponent,
    ConditionsModalComponent,
    ValidCouponComponent

  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    MyPipesModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    HeaderComponent,
    HeaderHomeComponent,
    AccordionComponent,
    EditIbanComponent,
    AddressInputComponent,
    AddressInputDumbComponent,
    WebUploadProductComponent,
    TabsComponent,
    ValidCouponComponent
  ]
})
export class SharedModule { }
