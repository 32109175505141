import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Product } from '../interfaces/product.interface';
import { ToastController } from '@ionic/angular';
import { ShippingItem, ShippingSeller, CartItem } from '../interfaces/shipping-item.model';
import { Plugins, StoragePlugin } from '@capacitor/core';
const { Storage } = Plugins;
import { environment as ENV } from '../../environments/environment';
import * as _  from "lodash";
import { PurchaseDetail, PurchaseDetailResponse } from '../interfaces/purchase-detail.interface';
// import { forEach } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MyCartService implements OnDestroy {

  private readonly MY_SHIPPING_CART_DB = 'myShippingItemsDB';
  private readonly PREMIER_OPTION_DB = 'premierOptionDB';
  private readonly SHIPPING_GRATIS = 1;
  private readonly SHIPPING_NORMAL = 2;
  private readonly SHIPPING_EXPRESS = 3;
  private readonly PRODUCT_STATUS_ACTIVO = 1;
  private readonly PREF_CODIGO_POSTAL = {
    LAS_PALMAS: '35',
    TENERIFE: '38'
  };
  readonly PRODUCT = {
    STATUS: {
      AVAILABLE: [1, 3]
    }
  };

  premier = false;
  buyerFromCanary = false;



  // Si es obligatorio que sea premier -> mandatory
  // Si es obligatorio que NO sea premier -> forbidden
  mandatoryPremier: 'mandatory' | 'forbidden' = undefined;
  // Mensaje de aviso.
  warningMsg: string;

  private myCartSub: BehaviorSubject<ShippingItem[]> = new BehaviorSubject([]);
  // Prohibida la compra
  private forbiddenPurchase: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // Shipping Items
  public myCartSub$: Observable<ShippingItem[]> = this.myCartSub.asObservable();
  // Product Items
  public cartItems$: Observable<CartItem[]> = this.myCartSub.asObservable().pipe(map((shippingItems: ShippingItem[]) => {
    const cartItems: CartItem[] = [];
    for (const si of shippingItems) {
      cartItems.push(...si.items);
    }
    // Cada vez que se vaya a guardar el carrito en local se actualiza el caso de canarias.
    this.updateCanaryCase();
    return cartItems;
  }));
  // Count cart items
  public cartItemsCount$: Observable<number> = this.myCartSub.asObservable().pipe(map((shippingItems: ShippingItem[]) => {
    let count;
    if (shippingItems && shippingItems.length > 0) {
      count = 0;
      for (const s of shippingItems) {
        count += s.countItems;
      }
    }
    return count;
  }));
  public forbiddenPurchase$: Observable<boolean> = this.forbiddenPurchase.asObservable();
  // Price cart items
  public cartItemsPrice$: Observable<number> = this.myCartSub.asObservable().pipe(map((shippingItems: ShippingItem[]) => {
    let total;
    if (shippingItems && shippingItems.length > 0) {
      total = 0;
      shippingItems.forEach(item => {
        total += item.getTotalPrice(this.premier);
      });
    }
    if (this.premier) {
      total += ShippingItem.EXTRA_PREMIER_PRICE;
    }
    return total;
  }));

  private storage: StoragePlugin;

  constructor(
    private http: HttpClient,
    private toastCtrl: ToastController
  ) {
    this.storage = Storage;
  }

  ngOnDestroy(): void {
    this.myCartSub.complete();
  }

  async addProduct(product: Product, logged: Boolean) {
    let added = false;

    if (this.PRODUCT.STATUS.AVAILABLE.includes(product.status_id)) {
      // const currentCart = this.myCartSub.value.slice();
      let currentCart = _.cloneDeep(this.myCartSub.getValue());
      let ownerId;
      if (product.on_deposit) {
        ownerId = -1;
      } else {
        ownerId = product.user.id;
      }
      let indexShipping = currentCart.findIndex((shiItem: ShippingItem) => shiItem.ownerId === ownerId );

      if (indexShipping < 0) {
        // Crear nuevo shipping
        const id = ownerId;
        const username = product.on_deposit ? 'Depósito' : product.user.username;
        const owner: ShippingSeller = {
          id,
          username
        };
        const shipping = new ShippingItem(owner);
        try {
          // Al agregar producto se comprueba si su procedencia es canarias.
          if (!product.on_deposit && this.isCanaryCode(product.user.addresses[0].postal_code)) {
            shipping.fromCanary = true;
          }
        } catch (error) {
          console.log('Error al obtener si es de canarias');
        }
        indexShipping = currentCart.push(shipping) - 1;
      }

      // Agregar producto a shipping
      added = currentCart[indexShipping].addItem(product);

      if (added) {
        console.log('****************1111');
  
          if(logged){
            this.addProductsToDB([product.id]);
          }else{
            await this.saveCartOnStorage(currentCart);
          }

        // Actualizar fuente
        this.updateCart(currentCart);
        this.presentToast(`Has agregado '${product.title}' a tu carrito`);
      } else {
        this.presentToast(`'${product.title}' ya está en tu carrito`);
      }
    } else {
      this.presentToast(`Este producto no está disponible`, 'warning');
    }
  }

public storageCartToDBCart (){

  let shippingItems = this.myCartSub.value;
  const cartItems: CartItem[] = [];
  for (const si of shippingItems) {
    cartItems.push(...si.items);
  }

  if(cartItems.length>0){
    let products : string[] = [];

    cartItems.forEach( cartItem => {
      products.push(cartItem.id);
    });

    if(products.length>0){
      const body = {products};
      const url = ENV.baseUrl + ENV.api.cart + '/add';
      //this.clearCart();
      return this.http.post<any>(url, body)
    }
  }    
  return of(0);
}

  private addProductsToDB (products: string[]) {
    try {
      const body = {products};
      const url = ENV.baseUrl + ENV.api.cart + '/add';
      this.http.post<any>(url, body).subscribe(data => {
      });
    }catch(error) {
      console.log('Error al añadir el producto en la BBDD');
      
    }
  }

  getSendingPrice() {
    return ShippingItem.SENDING_PRICE;
  }

  getExpressPrice() {
    return ShippingItem.EXTRA_EXPRESS_PRICE;
  }

  getPremierPrice() {
    return ShippingItem.EXTRA_PREMIER_PRICE;
  }

  // Stripe ONLY
  getPaymentMethods() {
    let url = ENV.baseUrl + ENV.api.checkoutPage + '/stripe/payment-methods';
    return this.http.get<any>(url);
  }

  clearCart() {
        this.updateCart([]);
  }

  async removeProduct(idCartItem: any, idOwner: any, logged: Boolean) {
    let currentCart = _.cloneDeep(this.myCartSub.getValue());
    //Busco el Shipping del dueño de la prenda
    const indexShipping = currentCart.findIndex((shiItem: ShippingItem) => shiItem.ownerId === idOwner );
    if (indexShipping > -1) {
      //shippingItems tiene las prendas del Shipping
      const shippingItems = currentCart[indexShipping].items;
      const product_id = idCartItem;
      //Busco la prenda a ser borrada
      const indexItem = shippingItems.findIndex(item => item.id === idCartItem);
      //Borro la prenda del array de prendas del Shipping
      shippingItems.splice(indexItem, 1);

      // currentCart[indexShipping].items = shippingItems;
      //¿Quedan prendas en el el Shipping de la dueña?
      if (currentCart[indexShipping].items.length === 0) {
        //NO! Elimino el Shipping de currentCart
        currentCart.splice(indexShipping, 1);
      }

      //TODO : Eliminar producto a DB

      if(logged){
        const url = ENV.baseUrl + ENV.api.cart + '/remove' + '/' + product_id;

        this.http.delete(url)
          .subscribe({
              next: data => {
              },
              error: error => {
                  console.error('There was an error!', error);
              }
          });
      }else{
        await this.saveCartOnStorage(currentCart);
      }

      console.log('****************3333');
      // Actualizar fuente
      this.updateCart(currentCart);
    }
    else {
      console.log('Imposible encontrar el Shipping');
    }
  }

  // TODO: Comprobar si el código postal es de canarias.
  updateCanaryCase(postalCodeBuyer?: string) {
    if (postalCodeBuyer) {
      this.buyerFromCanary = this.isCanaryCode(postalCodeBuyer);
    }
    const from = this.getShippingFrom();
    let msg;
    let msgLog;
    if (this.buyerFromCanary) {
      if (from.includes('canary') && from.includes('spain')) {
        // C Canarias - V España y Canarias --> Imposible
        msgLog = 'C Canarias - V España y Canarias --> Imposible';
        msg = 'La compra incluye productos de españa y de Canarias. Por favor haz la compra separando estos productos.';
        this.updateMandatoryParams(msg, undefined, true);
      } else if (from.includes('canary')) {
        // C Canarias - V Canarias --> NO Premier
        msgLog = 'C Canarias - V Canarias --> NO Premier';
        msg = 'Al ser una compra a Canarias no podrás seleccionar la opción premier';
        this.updateMandatoryParams(msg, 'forbidden');
      } else {
        // C Canarias - V España --> Premier Obligatorio
        msgLog = 'C Canarias - V España --> Premier Obligatorio';
        msg = 'Al incluir algunos productos localizados en península la opción de premier es obligatoria';
        this.updateMandatoryParams(msg, 'mandatory');
      }
    } else if (from.includes('canary')) {
      // C Península - V Alguno Canarias --> Premier Obligatorio
      msgLog = 'C Península - V Alguno Canarias --> Premier Obligatorio';
      msg = 'Al incluir algunos productos localizados en Canarias la opción de premier es obligatoria';
      this.updateMandatoryParams(msg, 'mandatory');
    } else {
      // C Península - V Península --> Normal
      msgLog = 'C Península - V Península --> Normal';
      this.updateMandatoryParams();
    }
    console.log(msgLog);
    console.log('msg: ', msg);
  }

  private updateMandatoryParams(warningMsg?: string, mandatoryPremier?: 'mandatory' | 'forbidden', forbiddenPurchase = false) {
    this.mandatoryPremier = mandatoryPremier;
    if (this.mandatoryPremier === 'mandatory') {
      this.premier = true;
    } else if (this.mandatoryPremier === 'forbidden') {
      this.premier = false;
    }
    this.warningMsg = warningMsg;
    this.forbiddenPurchase.next(forbiddenPurchase);
  }

  private isCanaryCode(postalCode: string): boolean {
    let canaryCode;
    if (
      postalCode && postalCode.length === 5 &&
      (postalCode.startsWith(this.PREF_CODIGO_POSTAL.LAS_PALMAS) || postalCode.startsWith(this.PREF_CODIGO_POSTAL.TENERIFE))) {
        canaryCode = true;
    } else {
      canaryCode = false;
    }
    return canaryCode;
  }

  private getShippingFrom(): ('canary' | 'spain')[] {
    const currentCart = this.myCartSub.value;
    const from: ('canary' | 'spain')[] = [];
    for (const s of currentCart) {
      if (s.fromCanary && !from.includes('canary')) {
        from.push('canary');
      } else if (!from.includes('spain')) {
        from.push('spain');
      }
      if (from.includes('canary') && from.includes('spain')) {
        break;
      }
    }
    return from;
  }

  private getObjToBuy() {
    const currentCart = this.myCartSub.value;
    const shippings: { owner: any, express: boolean, items: any[] }[] = [];
    currentCart.forEach(s => {
      const items: {id: any, price: number, onDeposit: boolean }[] = [];
      s.items.forEach(i => {
        items.push({ id: i.id, price: i.price, onDeposit: s.onDeposit });
      });
      let expressFlag;
      ( this.premier ) ? ( expressFlag = false ) : ( expressFlag = s.express ) ;
      const shi = { owner: s.ownerId, items, express: expressFlag };
      shippings.push(shi);
    });
    return shippings;
  }

  public async toggleExpressShipping(ownerId: any) {
    let currentValue = _.cloneDeep(this.myCartSub.getValue());
    console.log('toggleExpressShipping');
    const indexShipping = currentValue.findIndex((s) => s.ownerId === ownerId);
    if (indexShipping > -1) {
      // currentValue[indexShipping].express = !currentValue[indexShipping].express;
      console.log('****************4444');
      this.updateCart(currentValue);
    }
  }

  public async togglePremier() {
    console.log('****************5555');
    console.log('PREMIER****---> ' + this.premier);
    //Se debe desmarcar la opción de urgente en todos los envíos
    let currentValue = _.cloneDeep(this.myCartSub.getValue());
    currentValue.forEach((s)=> s.express = false);
    this.updateCart(currentValue);
  }

  createPayment(addressId: any, type: 'stripe' | 'paypal', amount: string, amount_with_discount: string, coupons: string[]) {
    const shippings = JSON.stringify(this.getObjToBuy());
    let url = ENV.baseUrl + ENV.api.checkoutPage;

    if (type === 'stripe') {
      url += '/stripe/create-payment-intent';
    } else {
      url += '/paypal/set-express-checkout';
    }
    const options = {
      shippings,
      address: addressId,
      premier: this.premier,
      amount: amount,
      amount_with_discount: amount_with_discount,
      coupons: coupons
    };
    return this.http.post<any>(url, options);
  }

  private updateCart(shippingItems: ShippingItem[]) {
    console.log('****************6666');
    // Colocar shipping on deposit al final del array.
    const indexDeposit = shippingItems.findIndex(s => s.onDeposit);
    let orderedShippingItems = [];
    if (indexDeposit > -1) {
      orderedShippingItems = _.cloneDeep(shippingItems);
      orderedShippingItems.splice(indexDeposit,1);
      orderedShippingItems.push(shippingItems[indexDeposit]);
      shippingItems = orderedShippingItems;
    } 

    this.myCartSub.next(shippingItems);

  }

  private async presentToast(message, color: 'primary' | 'warning' = 'primary') {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      color,
      position: 'middle'
    });
    toast.present();
  }



  public loadCartFromDB(shippingsExpressConfig? : CartShippingsExpressConfig) {
    const url = ENV.baseUrl + ENV.api.cart;

    this.http.get<any>(url).subscribe(async cart => {
      console.log('LOAD CART FROM DB');
      console.log(cart);
      if (cart) {
        const cartParsed: ShippingItem[] = [];
        cart.map(c => {
          const onDeposit = c.on_deposit || false;
          if(onDeposit && cartParsed.some(shi => shi.onDeposit)){
              const item: CartItem = {};
              item.id = c.id;
              item.main_image = c.main_image;
              item.price = Number(c.sale_price);
              item.status_id = c.status_id;
              item.title = c.title;
              item.sellerName = c.user.username;
              item.slug = c.slug;
              cartParsed.find(shi => shi.onDeposit).items.push(item);
          }else if(!onDeposit && cartParsed.some(shi => shi.ownerId === c.user.id)){
              const item: CartItem = {};
              item.id = c.id;
              item.main_image = c.main_image;
              item.price = Number(c.sale_price);
              item.status_id = c.status_id;
              item.title = c.title;
              item.sellerName = c.user.username;
              item.slug = c.slug;
              cartParsed.find(shi => shi.ownerId === c.user.id).items.push(item);
          }else{
            cartParsed.push(ShippingItem.fromDB(c));
          }
           
          if(shippingsExpressConfig && shippingsExpressConfig?.shippings?.length>0 ){

            shippingsExpressConfig.shippings.forEach(shi => {
              cartParsed.find(sh => sh.ownerId === shi.senderId)?
              cartParsed.find(sh => sh.ownerId === shi.senderId).express = shi.express :
              ''
            })
      
          }

        });
        
         this.updateCart(cartParsed);
      }});


    }

    public async loadCartFromStorage() {
      await this.storage.get({ key: this.PREMIER_OPTION_DB }).then(p => {
        if (p && p.value) {
          const premier = JSON.parse(p.value);
          // Set Premier a valor del localstorage
          this.premier = premier;
        }
      }).catch(e => {
        console.log('Error getting my premier option from local');
      });
  
      await this.storage.get({ key: this.MY_SHIPPING_CART_DB }).then((cart) => {
        console.log('LOAD CART FROM STORAGE');
        if (cart && cart.value) {
          const cartDB = JSON.parse(cart.value);
          const cartParsed: ShippingItem[] = [];
          cartDB.map(c => {
            cartParsed.push(ShippingItem.fromObj(c));
          });
          // Set myCartSub a valor del localstorage
          this.myCartSub.next(cartParsed);
        }
      }).catch(e => {
        console.log('Error getting my cart from local');
      });
    }
  

      private async saveCartOnStorage(shippingItems: ShippingItem[]) {
        const value = JSON.stringify(shippingItems);
        const premier = JSON.stringify(Boolean(this.premier));
        console.log(shippingItems);

        await this.storage.set({ key: this.PREMIER_OPTION_DB, value: premier}).then(() => {
          console.log('Saved option premier on local');
        }).catch(e => {
          console.log('Error saving option premier: ', e);
        });

        await this.storage.set({ key: this.MY_SHIPPING_CART_DB, value: value }).then(() => {
          console.log('Cart saved on local');
        }).catch(e => {
          console.log('Error saving my cart from local');
        });
        
        this.myCartSub.next(shippingItems);
      }


      public isAnyProductNotActive(): boolean {
        
        return this.myCartSub.getValue().some(shippItem =>  
          shippItem.items.some(item => item.status_id !== 1));

      }

      public myCartShippingConfig(): CartShippingsExpressConfig{

        let cartShippingsExpressConfig: CartShippingsExpressConfig;

        if(this.myCartSub.getValue().length>0){
          this.myCartSub.getValue().forEach((shippingItem: ShippingItem) => {
            if( cartShippingsExpressConfig && !cartShippingsExpressConfig.shippings.some(cartShipping => cartShipping.senderId === shippingItem.ownerId) ){

              cartShippingsExpressConfig.shippings.push(
                {senderId: shippingItem.ownerId , 
                  express: shippingItem.express});

            } else {
              cartShippingsExpressConfig = {shippings : [{senderId: shippingItem.ownerId , 
                express: shippingItem.express}]}
            }
          });
        }

        return cartShippingsExpressConfig;
      }

 
      public removeNotActiveProducts(logged : boolean){
        let currentValue = _.cloneDeep(this.myCartSub.getValue());
        currentValue.forEach(s=> s.items.forEach(item => {

          item.status_id !== this.PRODUCT_STATUS_ACTIVO ?
          this.removeProduct(item.id, s.ownerId, logged) :
          '';
          
        }));
      }
}

export interface CartShippingsExpressConfig{
  shippings?: [{
    senderId?: number,
    express?: boolean
  }]
}
