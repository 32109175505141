import { Component, OnInit, Input } from '@angular/core';
import { BalanceItem } from '../../interfaces/balance-item.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-item-accordion',
  templateUrl: './item-accordion.component.html',
  styleUrls: ['./item-accordion.component.scss'],
})
export class ItemAccordionComponent implements OnInit {

  @Input() item: BalanceItem;
  @Input() typeItem: 'purchase' | 'sale';

  constructor(
    private router: Router
  ) { }

  get type(): 'Pedido' | 'Envío' {
    let res: 'Pedido' | 'Envío';
    if (this.typeItem === 'purchase') {
      res = 'Pedido';
    } else {
      res = 'Envío';
    }
    return res;
  }

  ngOnInit() {}

  goToDetail() {
    console.log('Ir a detalle de ', this.typeItem, ' --> item: ', this.item.id);
    if (this.typeItem === 'purchase') {
      this.router.navigate(['/', 'perfil', 'configuracion', 'compras', this.item.id]);
    } else {
      this.router.navigate(['/', 'perfil', 'configuracion', 'ventas', this.item.id]);
    }
  }

  getBackImage(url: any) {
    let res;
    if (url) {
      res = { 'background-image': `url('${url}'), url('/assets/img/product.svg')` };
    } else {
      res = { 'background-image': `url('/assets/img/product.svg')` };
    }
    return res;
  }

}
