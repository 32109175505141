import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, ModalController, PopoverController } from '@ionic/angular';
import { FilterProductService } from 'src/app/services/filter-product.service';
import { ListItemsService } from 'src/app/services/list-items.service';
import { UserService } from 'src/app/services/user.service';
import { ModalFilterPage } from '../../pages/modals/modal-filter/modal-filter.page';
import { MenuPopoverComponent } from './menu-popover/menu-popover.component';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss'],
})
export class HeaderHomeComponent implements OnInit {

  readonly logo = './assets/img/logoWhite.svg';

  readonly homeIcon = './assets/img/footer/home.svg';
  readonly favIcon = './assets/img/footer/favorito.svg';
  readonly newIcon = './assets/img/footer/subir.svg';
  readonly alertIcon = './assets/img/footer/notificaciones.svg';
  readonly profileIcon = './assets/img/footer/perfil.svg';
  readonly searchIcon = './assets/img/footer/buscar.svg';


  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private popoverController: PopoverController,
    private productFilters: FilterProductService,
    private userSrv: UserService,
    public listItemsSvc: ListItemsService,
    private router: Router
  ) { }

  ngOnInit() {}

  goToMyCart() {
    this.navCtrl.navigateForward('/micarrito');
  }

  async search() {
    this.router.navigate(['/escaparate']);
    console.log('buscar');
    const modal = await this.modalCtrl.create({
      component: ModalFilterPage
    });

    return await modal.present();
  }

  async presentPopoverMenu(ev: any) {
    const popover = await this.popoverController.create({
      component: MenuPopoverComponent,
      mode: 'ios',
      event: ev,
      translucent: true
    });
    popover.onWillDismiss().then(
      res => {
        if (res && res.data && res.data.search) {
          this.search();
        }
      }
    );
    return await popover.present();
  }

  goToPage(page: string) {
    if (page === 'escaparate'){
      this.clearAndloadItems();
    }
    this.navCtrl.navigateRoot('/' + page);
  }

  loadItems(params: { event?: any; next?: boolean; } = {}) {
    if (this.listItemsSvc.isLoadingMore) {
      return;
    }
    this.listItemsSvc.isLoadingMore = true;
    setTimeout(() => {
      this.listItemsSvc.loadItems({ ...params });
    }, 0);
  }

  clearAndloadItems() {
    //Reseteamos filtros
    this.productFilters.resetFilters();
    this.userSrv.resetFilters();
    this.listItemsSvc.typeGallery = 'product';
    this.loadItems();
  }

}
