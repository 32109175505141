import { Component, OnInit, Input } from '@angular/core';
import { BalanceMonthItem } from '../../interfaces/balance-month-item.interface';
import { BalanceItem } from 'src/app/interfaces/balance-item.interface';
import { BalanceItemService } from '../../interfaces/balance-item-service.interface';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {

  @Input() monthItem: BalanceMonthItem;
  @Input() svc: BalanceItemService;
  @Input() isFirst = false;
  @Input() typeItem: 'purchase' | 'sale';
  items: BalanceItem[] = [];
  loading = false;
  showList = false;
  maxHeight = '0';
  private heightRow = 250;

  constructor() { }

  ngOnInit() {
    if (this.isFirst) {
      this.toggleMonth();
    }
  }

  monthText(monthYear: BalanceMonthItem): string {
    let res;
    const months = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    res = months[monthYear.month - 1];
    const now = new Date();
    if (now.getFullYear() !== monthYear.year) {
      res += ' ' + monthYear.year;
    }
    return res;
  }

  toggleMonth() {
    if (!this.loading) {
      if (this.showList === false && this.items.length === 0 ) {
        this.loading = true;
        this.svc.getBalanceItems(this.monthItem)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              this.loading = false;
            }, 500);
          })
        )
        .subscribe(
          items => {
            console.log(items);
            this.items = items;
            this.expandList(true);
          },
          error => {
            console.log('Error: ', error);
          }
        );
      } else {
        this.expandList(!this.showList);
      }
    }
  }

  trackByFn(index, item) {
    return index;
  }

  private expandList(expand: boolean) {
    this.showList = expand;
    if (expand) {
      this.maxHeight = (this.heightRow * this.items.length) + 'px';
    } else {
      this.maxHeight = '0';
    }
  }

}
