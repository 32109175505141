import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AlertController, ToastController } from '@ionic/angular';
import * as IBAN from 'iban';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-edit-iban',
  templateUrl: './edit-iban.component.html',
  styleUrls: ['./edit-iban.component.scss'],
})
export class EditIbanComponent implements OnInit {

  ibanForm: FormGroup;
  currentIban: string;
  editMode = false;
  loading = false;

  constructor(
    private toastCtrl: ToastController,
    private fb: FormBuilder,
    private authSvc: AuthenticationService,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this.initDataAndForm();
  }

  async onSubmit() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Cambiar datos de Ingresos',
      message: 'Si aceptas se modificará tus datos de ingreso con el IBAN introducido',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        }, {
          text: 'Aceptar',
          handler: () => {
            console.log('Confirm Okay');
            this.submitForm();
          }
        }
      ]
    });

    await alert.present();
  }

  setEditMode(status: boolean) {
    this.editMode = status;
    if (!status) {
      this.initForm();
    }
  }

  private submitForm() {
    this.loading = true;
    this.authSvc.updateIbanOnServer(this.ibanForm.value)
    .pipe(
      finalize(() => { this.loading = false; })
    )
    .subscribe(
      () => {
        this.editMode = false;
        this.presentToast('El IBAN ha sido modificado', 'primary');
        this.currentIban = this.ibanForm.value.iban;
      }, e => {
        console.log('error: ', e);
        this.presentToast('Se produjo un error al intentar modificar el IBAN', 'warning');
      }
    );
  }

  private initDataAndForm() {
    this.authSvc.getIbanFromServer()
    .subscribe(
      data => {
        if (data && data.iban) {
          this.currentIban = data.iban;
        }
        this.initForm();
      },
      error => {
        console.log('Error: ', error);
      }
    );
  }

  private initForm() {
    this.ibanForm = this.fb.group({
      iban: [this.currentIban, [this.ibanValidator]]
    });
  }

  private async presentToast(message, color: 'primary' | 'warning' = 'primary', duration = 2000) {
    const toast = await this.toastCtrl.create({
        message,
        duration,
        color,
        position: 'middle'
    });
    toast.present();
  }

  private ibanValidator(control: FormControl): ValidationErrors {
    let res: any;
    const valid = IBAN.isValid(control.value);
    if (!valid) {
      res = { ibanError: true };
    }
    return res;
  }

}
