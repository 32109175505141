import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificationsService } from '../services/notifications.service';
import { AuthenticationService } from '../services/authentication.service';
@Injectable()
export class NotificationInterceptor implements HttpInterceptor {

    lastDate = Date.now();
    private notificationsInProgress = false;
    constructor(
        private notSvc: NotificationsService,
        private authSvc: AuthenticationService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //Evitar las peticiones "GET Total Notificaciones" si...
        if (!request.url.includes('/notifications') && !request.url.includes('/auth') && (Date.now() - this.lastDate > 60000)) {
            // Check si existe "accessToken" para hacer petición a "total notificaciones"
            this.authSvc.getToken('token')
                .then(token => {
                    // Si ya se están pidiendo notificaciones por otra intercepción, entonces evitar nueva petición
                    if (token && !this.notificationsInProgress) {
                        this.lastDate = Date.now();
                        this.notificationsInProgress = true;
                        this.notSvc.getUnreadNotifications().subscribe(
                            (sumNotifShip) => {
                                this.notificationsInProgress = false;
                                this.notSvc.updateCount(sumNotifShip);
                            },
                            (error)=> {
                                console.log('ERROR GET TOTAL NOTIFICACIONES --> ', error);
                                this.notificationsInProgress = false;
                            }
                        );
                    }
                })
                .catch(error => {
                    console.log('ERROR NO EXISTE TOKEN PARA RECOGER TOTAL NOTIF --> ', error);
                });
        }

        return next.handle(request);
    }
}

