import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { MyCartService } from '../../services/my-cart.service';

@Component({
  selector: 'app-bag-button',
  templateUrl: './bag-button.component.html',
  styleUrls: ['./bag-button.component.scss'],
})
export class BagButtonComponent implements OnInit, OnDestroy {

  count = 0;
  countSubscription: Subscription;

  constructor(
    private zone: NgZone,
    public myCartSvc: MyCartService
  ) { }

  ngOnInit() {
    this.countSubscription = this.myCartSvc.cartItemsCount$.subscribe(
      count => {
        this.count = count;
        this.refreshPage();
      },
      e => {
        console.log(e);
      }
    );
  }

  ngOnDestroy(): void {
    this.countSubscription.unsubscribe();
  }

  private refreshPage() {
    this.zone.run(() => {
    //  console.log('Refrescado con ngZone');
    });
  }
}
