import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, from, iif, Observable, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { ProductItem, ProductFilter } from '../interfaces/product.interface';
import { UserItem } from '../interfaces/user.interface';
import { ProductService } from './product.service';
import { UserService } from './user.service';
import { FilterProductService } from './filter-product.service';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ListItemsService {

  private productListSubject: BehaviorSubject<ProductItem[]> = new BehaviorSubject<ProductItem[]>([]);
  public products$: Observable<ProductItem[]> = this.productListSubject.asObservable();

  private userListSubject: BehaviorSubject<UserItem[]> = new BehaviorSubject<UserItem[]>([]);
  public users$: Observable<UserItem[]> = this.userListSubject.asObservable();

  // - Params para home page
  infiniteDisabled = false;
  isLoading = false;
  showFilterHeader = false;
  typeGallery: 'product' | 'user' = 'product';
  // para desahabilitar el infinitescroll mientras cargan más
  isLoadingMore = false;

  // - Pagination params
  pageSize = 25;
  sortField = 'created_at';
  sortOrder: 'asc' | 'desc' = 'desc';
  // Index de showcase
  nextPageIndexSC = 1;
  // Index en products y users
  nextPageIndex = 1;

  showcaseCompleted = false;

  totalShowcaseProducts = 0;

  constructor(
    private productSvc: ProductService,
    private userSvc: UserService,
    private filtersProdSvc: FilterProductService,
    private http: HttpClient
  ) { }

  followUser(userId: any, updateList: boolean = false): Observable<{ follow: any; }> {
    const url = ENV.baseUrl + ENV.api.users + '/follow';
    return this.http.post<{ follow: any; }>(url, { userId }).pipe(map(data => {
      if (updateList && data && data.follow !== undefined) {
        this.updateFollowUsers(userId, data.follow);
      }
      return data;
    }));
  }

  loadItems(params: { event?: any; next?: boolean; } = {}) {
    const { event, next } = { event: undefined, next: false, ...params };
    if (!next) {
      this.initList();
    }
    if (this.typeGallery === 'user') {
      this.showFilterHeader = true;
      this.loadUsers(event);
    } else {
      const filtersProducts: ProductFilter = this.filtersProdSvc.getCurrentFilters();
      const countFilters = Object.keys(filtersProducts).length;
      if (!countFilters) {
        this.showFilterHeader = false;
      } else {
        this.showFilterHeader = true;
      }
      if (!countFilters && !this.showcaseCompleted) {
        this.loadShowcase(event);
      } else {
        this.loadProducts(event, filtersProducts);
      }
    }
  }

  // tslint:disable-next-line: variable-name
  updateLikesProduct(newTotalLikes: number, product_id: any, i_like: boolean) {
    let value = this.productListSubject.value;
    const i = value.findIndex(p => p.id === product_id);
    if (i > -1) {
      value[i].total_likes = newTotalLikes;
      value[i].i_like = i_like;
      value = [...value];
    }
    this.productListSubject.next(value);
  }


  private updateFollowUsers(userId: any, follow: boolean) {
    let value = this.userListSubject.value;
    const i = value.findIndex(u => u.id === userId);
    if (i > -1) {
      value[i].i_follow = follow;
      value = [...value];
      this.userListSubject.next(value);
    }
  }

  private initList() {
    this.isLoading = true;
    this.isLoadingMore = true;
    this.infiniteDisabled = false;
    this.showcaseCompleted = false;
    this.nextPageIndex = 1;
    this.nextPageIndexSC = 1;
    this.productListSubject.next([]);
    this.userListSubject.next([]);
  }

  private loadProducts(event?: any, filtersProducts?: ProductFilter) {
    this.productSvc.getProducts(this.nextPageIndex, filtersProducts)
    .pipe(finalize(() => {
      this.isLoading = false;
      this.isLoadingMore = false;
      this.nextPageIndex++;
      if (event) {
        event.target.complete();
      }
    }))
    .subscribe(res => {
      const totalProducts = Number(res.headers.get('X-Total-Count'));
      const value = this.productListSubject.value;
      value.push(...res.body);
      this.productListSubject.next(value);
      if (totalProducts <= (value.length - this.totalShowcaseProducts)) {
        this.infiniteDisabled = true;
      }
    });
  }

  private loadShowcase(event?: any) {
    this.productSvc.getShowcase(this.nextPageIndexSC)
    .pipe(
      map(res => {
        const totalShowcase = Number(res.headers.get('X-Total-Count'));
        this.totalShowcaseProducts = totalShowcase;
        const value = this.productListSubject.value;
        const body = res.body;
        const newItems = body.map(i => i.product);
        value.push(...newItems);
        if (totalShowcase <= value.length) {
          this.showcaseCompleted = true;
        }
        return value;
      }),
      switchMap((products: ProductItem[]) => {
        return iif(
          () => {
            return this.showcaseCompleted;
          },
          this.productSvc.getProducts(this.nextPageIndex)
          .pipe(
            map((res) => {
              const totalProducts = Number(res.headers.get('X-Total-Count'));
              products.push(...res.body);
              this.productListSubject.next(products);
              if (totalProducts <= (products.length - this.totalShowcaseProducts)) {
                this.infiniteDisabled = true;
              }
            }),
            finalize(() => {
              this.nextPageIndex++;
            })
          ),
          EMPTY
        );
      }),
      finalize(() => {
        this.isLoading = false;
        this.isLoadingMore = false;
        this.nextPageIndexSC++;
        if (event) {
          event.target.complete();
        }
      }
    ))
    .subscribe(res => {
    }, e => {
      console.log('Error: ', e);
    });
  }

  private loadUsers(event?: any) {
    this.userSvc.getUsers(this.nextPageIndex)
    .pipe(finalize(() => {
      this.isLoading = false;
      this.isLoadingMore = false;
      this.nextPageIndex++;
      if (event) {
        event.target.complete();
      }
    }))
    .subscribe(res => {
      const totalUsers = Number(res.headers.get('X-Total-Count'));
      const value = this.userListSubject.value;
      value.push(...res.body);
      console.log(value);
      this.userListSubject.next(value);
      if (totalUsers <= value.length) {
        this.infiniteDisabled = true;
      }
    });
  }
}
