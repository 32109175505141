import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Address } from '../../interfaces/address.interface';
import { UserService } from '../../services/user.service';
import { MyCartService } from '../../services/my-cart.service';
import { ToastController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
})
export class AddressInputComponent implements OnChanges {

  @Output()
  successSaveNewAddress = new EventEmitter<boolean>();
  @Output()
  addressSelected = new EventEmitter<Address>();
  @Output() isAddressEditMode = new EventEmitter<boolean>();
  isEditMode = false;
  @Input() title = '';
  @Input() userId: User;
  address: Address;
  invalidPostalCodes = [];

  addressForm: FormGroup;
  submitted = false;

  @Input() date: number;


  constructor(
    private fb: FormBuilder,
    private userSvc: UserService,
    private myCartSvc: MyCartService,
    private toastCtrl: ToastController,
    private authSvc: AuthenticationService
  ) { }

  get addressId(): any {
    let res;
    if (this.address) {
      res = this.address.id;
    }
    return res;
  }


  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
            case 'userId':
              if(changes.userId.currentValue) {
              //We have an User
              this.getAddressToSend() 
            } else {
              //Err. This is a Logout probably
              if (this.addressForm)
                this.addressForm.reset(); 
              else 
                console.log('Nothing to reset');
            }
            case 'date':
              if(changes.date.currentValue) {
                this.getAddressToSend() 
              }
          break;    
        }
      }
    } 
  }

  changeEditMode(newEditMode: boolean) {

    this.isEditMode = newEditMode;
    this.isAddressEditMode.emit(this.isEditMode);
  }

  onSubmit() {
    this.userSvc.setUserAddress(this.addressForm.value).subscribe(
      newAddress => {
        console.log(newAddress);
        this.setAddress({ ...newAddress });
        this.changeEditMode(false);
        this.successSaveNewAddress.emit(true);
        this.addressSelected.emit(newAddress);
        this.presentToast('Se guardaron los datos de la dirección', 'primary', 3000);
      },
      e => {
        this.submitted = true;
        console.log('Error: ', e);
        if (e && e.status === 409) {
          if (e.error && e.error.data) {
            this.invalidPostalCodes.push(e.error.data.postal_code);
          }
          this.presentToast('Código postal inválido', 'warning', 4000);
        } else {
          this.presentToast('Se ha producido un error en el envío de la dirección', 'warning', 4000);
        }
      }
    );
  }

  private getAddressToSend() {
    this.userSvc.getUserAddress().subscribe(

      (addressesUser: Address[]) => {
        console.log('start');
        if (addressesUser.length > 0) {
          this.setAddress(addressesUser[0]);
          this.addressSelected.emit(addressesUser[0]);
          this.isEditMode = false;
        } else {
          this.setAddress({});
          this.addressSelected.emit();
          this.isEditMode = false; 
        }
        this.isAddressEditMode.emit(this.isEditMode);
        this.loadForm();
        console.log('end');
      },
      e => {
        console.log('Error al obtener dirección de envío');
        // En caso de no ser una dirección válida se rellenan los campos si se reciben (solo se recibirán los válidos)
        // TODO: recibir data y rellenar:
        this.setAddress({});
        this.isEditMode = true;
        this.isAddressEditMode.emit(this.isEditMode);
        this.addressSelected.emit();
        this.loadForm();
      }
    );
  }

  private setAddress(address: Address) {
    this.address = address;
    let postalCode;
    if (this.address) {
      postalCode = this.address.postal_code;
    }
    this.myCartSvc.updateCanaryCase(postalCode);
  }

  private loadForm() {
    this.addressForm = this.fb.group({
      address: [this.address.address, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      city: [this.address.city, [Validators.required, Validators.minLength(3), Validators.maxLength(80)]],
      province: [this.address.province, [Validators.required, Validators.minLength(3), Validators.maxLength(80)]],
      country: [{value: 'España', disabled: true}, [Validators.required, Validators.minLength(2), Validators.maxLength(80)]],
      phone: [this.address.phone, [Validators.required, Validators.minLength(9), Validators.maxLength(50)]],
      postal_code: [
        this.address.postal_code,
        [ Validators.required, Validators.minLength(3), Validators.maxLength(5), this.validPostalCode(this.invalidPostalCodes)]],
      contact: [this.address.contact, [Validators.required]]
    });
  }

  private validPostalCode(invalidCodes: any[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const value = control.value;
      if (invalidCodes.includes(value)) {
        return {invalidCode: {value: true}};
      }
      return null;
    };
  }

  private async presentToast(message, color: 'primary' | 'warning' = 'primary', duration = 2000) {
    const toast = await this.toastCtrl.create({
        message,
        duration,
        color,
        position: 'middle'
    });
    toast.present();
}
}
