import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {}

     
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            console.warn("AuthGuard");
            console.log(next);
            console.log(state);
            console.log(this.router);
            return this.isAuthenticatedOrNavigate(state.url);
    }


    async isAuthenticatedOrNavigate(returnUrl: string ): Promise <boolean |UrlTree>  {
    
        const isAuthenticated = await this.authService.checkAuthentication();

        if (isAuthenticated) {
            console.warn("isAuthenticated")
            return true;

        } else {
            console.warn("isNotAuthenticated: Navigate")
            return this.router.createUrlTree(['login'], { queryParams: { returnUrl }});
        }
    }
}
