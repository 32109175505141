import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { tap, switchMap, map, catchError } from 'rxjs/operators';
import { Plugins, StoragePlugin } from '@capacitor/core';
const { Storage } = Plugins;
import { environment as ENV } from '../../environments/environment';
import { User } from '../interfaces/user.interface';
import { Router } from '@angular/router';
import { ListItemsService } from './list-items.service';
import { MyCartService } from './my-cart.service';
import { FcmService } from './fcm.service';
import { NotificationsService } from './notifications.service';
import { FilterService } from './filter.service';
import jwtDecode from 'jwt-decode';

interface LoginResponse {
    accessToken: string;
    refreshToken: string;
    user: User;
}

type tokenTypeDB = 'token' | 'refreshToken';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private USER_TOKEN_DB: tokenTypeDB = 'token';
    private USER_REFRESH_TOKEN_DB: tokenTypeDB = 'refreshToken';

    private storage: StoragePlugin;

    constructor(
        private http: HttpClient,
        private listItemsSvc: ListItemsService,
        private router: Router,
        private cartSvc: MyCartService,
        private fcmService: FcmService,
        private filterService: FilterService,
        private notSvc: NotificationsService
    ) {
        this.storage = Storage;
    }

    login(form: { username: string; password: string }): Observable<any> {
        const url = ENV.baseUrl + ENV.api.auth + '/login';
        return this.http.post<LoginResponse>(url, form).
        pipe(
            switchMap(res => {
                let promises = [this.setToken(this.USER_TOKEN_DB, res.accessToken), this.setToken(this.USER_REFRESH_TOKEN_DB, res.refreshToken)];
                return Promise.all(promises).then(() => {
                    //Refresh Escaparate
                    this.filterService.clearAndLoadItems();
                    // Trigger the push notifications setup
                    this.fcmService.initPush();
                    
                });
            }),
            switchMap(() => this.cartSvc.storageCartToDBCart()),
            switchMap(() => {
                //this.cartSvc.clearCart();
                this.cartSvc.loadCartFromDB();
                //HACK. Make LoadCartFromDB to return an Observable to chain them properly!!!
                return of(true);
            }),
            switchMap(()=> this.notSvc.getUnreadNotifications()),
            tap ((sumNotifShip) => {
                this.notSvc.updateCount(sumNotifShip);
            },
            e => {
              console.log('Error: ', e);
            }));
    }

    async logout(): Promise<any> {
        this.notSvc.resetCount();
        
        // Deshabilitar las notificaciones push del dispositivo
        try {
            await this.fcmService.disablePush(); // Internamente comprueba si está en ios/android
        } catch(error) {
            console.log('ERROR DISABLING DEVICE PUSH NOTIFICATIONS WHEN LOGOUT --> ', error);
        }

        try {
            await this.storage.clear();
        } catch(error) {
            console.log('ERROR DELETING STORAGE ON LOGOUT --> ', error);
        }

        // ANTIGUO FLUJO LOGOUT FRAN (NO MODIFICADO)
        //Refresh Escaparate
        this.filterService.clearAndLoadItems();

        this.router.navigate(['/login']);
        this.cartSvc.clearCart();
        this.listItemsSvc.typeGallery = 'product';
    }

    register(form: { username: string; password: string, email: string }) {
        const url = ENV.baseUrl + ENV.api.auth + '/register';
        return this.http.post<LoginResponse>(url, form);
    }

    refreshToken(): Observable<{accessToken: string; refreshToken: string}> {
        const obs = from(this.storage.get({ key: this.USER_REFRESH_TOKEN_DB })).pipe(
            map(data => data.value )
        );

        return obs.pipe(
            switchMap(refreshToken => {
                const url = ENV.baseUrl + ENV.api.auth + '/refresh';
                return this.http.post<{accessToken: string; refreshToken: string}>(
                    url,
                    { refreshToken }
                );
            }),
            tap(async (res) => {
                //Asincronas. No criticas porque al ejecutor de .refreshToken() le estamos pasando estos valores.
                await this.setToken(this.USER_TOKEN_DB, res.accessToken);
                await this.setToken(this.USER_REFRESH_TOKEN_DB, res.refreshToken);
            })
        );
    }

    // Enviar petición para modificar contraseña
    resetpassword(form: { email: string }): Observable<any> {
        const url = ENV.baseUrl + ENV.api.auth + '/recover';
        return this.http.post<LoginResponse>(url, form);
    }

    // Enviar nueva contraseña
    setNewPassword(form: { code: string, newPassword: string }): Observable<any> {
        const url = ENV.baseUrl + ENV.api.auth + '/new-password';
        return this.http.post<LoginResponse>(url, form);
    }

    getToken(key: tokenTypeDB): Promise<string> {
        return this.storage.get({ key }).then(
            res => { 
                return res.value;            
            }
        );
    }

    changePassword(oldPassword, newPassword): Observable<any> {
        const url = ENV.baseUrl + ENV.api.auth + '/password';
        const body = {
            oldPassword,
            newPassword
        };
        return this.http.put<any>(url, body);
    }

    setDataProfile(form: any, block: 'first' | 'second', photo?: string): Observable<any> {
        const url = ENV.baseUrl + ENV.api.users + '/profile';
        const formData = new FormData();
        for (const key in form) {
            if (Object.prototype.hasOwnProperty.call(form, key) && (form[key] || form[key] === 0)) {
                formData.append(`${key}`, form[key]);
            }
        }
        if (photo) {
            const photoName = form?.username ? form.username : 'useravatar';
            if (!photo.startsWith('http')) {
              formData.append('avatar', this.b64ToBlob(photo), `${photoName}`);
            }
        } else if (photo === '') {
            formData.append('removeAvatar', 'true');
        }
        formData.append('block', block);
        return this.http.put<any>(url, formData);
    }

    getIbanFromServer(): Observable<{ iban: string; }> {
        const url = ENV.baseUrl + ENV.api.iban;
        return this.http.get<{ iban: string; }>(url);
    }

    updateIbanOnServer(form: { iban?: string }): Observable<any> {
        const url = ENV.baseUrl + ENV.api.iban;
        return this.http.put<any>(url, form);
    }

    isUniqueValue(field: 'email' | 'username', value: string): Observable<{ valid: boolean }> {
        const url = ENV.baseUrl + ENV.api.users + '/unique';
        const params = new HttpParams().append(field, value);
        return this.http.get<{ valid: boolean }>(url, { params });
    }

    private b64ToBlob(b64Img: string): Blob {
        const rawData = atob(b64Img);
        const bytes = new Array(rawData.length);
        for (let x = 0; x < rawData.length; x++) {
            bytes[x] = rawData.charCodeAt(x);
        }
        const arr = new Uint8Array(bytes);
        const blob = new Blob([arr], {type: 'image/png'});
        return blob;
    }

    private setToken(key: tokenTypeDB, token: string): Promise<any> {
        return this.storage.set({ key, value: token }).then(() => {
            console.log(`Seteado ${key}`);
        }).catch(e => {
            console.log(`Set ${key} error: ${e}`);
        });
    }

    public userLoggedWithValidToken (value: tokenTypeDB) : Promise<string> {
            return this.getToken(value).then((token: string) => {
                
                if (token){
                    console.log(token);
                    let decoded: any = jwtDecode(token);
                    var date = Date.now();
                    
                    console.log(decoded.exp*1000, date)
                    if ((decoded.exp * 1000) > date) {
                        return token;
                    } else {
                        console.log("token caducado");
                        return null;
                    }

                } else {
                    console.log("no existe token de refresh");
                    return null;
                }
                
     
             }).catch(e => {
                 console.log(e);
                 return null;
             });
         
     }

    public async isValidAccessToken (token: string) : Promise<boolean> {
        
            console.warn(token);

            let decoded: any = jwtDecode(token);
            let date = Date.now();

            console.log(decoded.exp*1000, date)

            if ((decoded.exp * 1000) > date) {
                return true;
            } else 
            {
                console.log("Token caducado");
                return false;
            }
    }

    public async isValidRefreshToken (token: string): Promise <true | false> {

            console.warn(token);

            let decoded: any = jwtDecode(token);
            let date = Date.now();

            console.log(decoded.exp*1000, date)

            if ((decoded.exp * 1000) > date) {
                return true;
            } else 
            {
                console.log("RefreshToken caducado");
                return false;
            }
    }

    public async isSetAccessToken(): Promise <boolean> {
        return this._isSetToken('token');
    }

    public async isSetRefreshToken(): Promise <boolean> {
        return this._isSetToken('refreshToken');
    }

    private async _isSetToken(tokenType: tokenTypeDB) {
        const token = await this.getToken(tokenType);
        if (token) {
            return true;
        } else {
            return false;
        }
    }

    public async checkAuthentication(): Promise <boolean>  {
    
        const isSetAccessToken = await this.isSetAccessToken();

        if (isSetAccessToken) {

            const accessToken = await this.getToken("token");
            const isValidAccessToken = await this.isValidAccessToken(accessToken);
            
            if (isValidAccessToken){
                //Access Token seteado y válido:
                console.warn("Access Token still valid");
                return true;
            } else {
                //Access Token seteado pero caducado. Probemos el refresh:
                const isSetRefreshToken = await this.isSetRefreshToken();

                if (isSetRefreshToken) {
                    //Access Token caducado, Refresh token seteado:
                    const refreshToken = await this.getToken("refreshToken");
                    const isValidRefreshToken = await this.isValidRefreshToken(refreshToken);
                    
                    if (isValidRefreshToken) {
                        //Access Token caducado, Refresh Token seteado y válido:
                        console.warn("Refresh Token still valid");
                        return true;
                    } else {
                        //Access Token caducado, Refresh Token seteado pero caducado:
                        return false;
                    }

                } else {
                    //No deberíamos entrar nunca aquí.
                    //Raro. Si el Access Token está seteado el RefreshToken se supone que también.
                    console.warn("Assert: Refresh Token debería estar seteado también");
                    //Access Token seteado pero caducado. Refresh token no existente: Escupimos a Login.
                    return false;
                }
                
            }
            
        } else {
            // No existe Access Token: Invitado o Usuario ha deslogado (ahora es invitado) o se ha limpiado el storage (ahora es invitado).
            console.warn("No Tokens!");
            return false;
        }
    }


    removeUser(form: {username: string, password: string}){
        const body = form;
        const url = ENV.baseUrl + ENV.api.auth + '/delete';
        return this.http.post<any>(url, body)
        .pipe(
            map( () => {
                return true ;
            })
            // ,
            // catchError( (err) => {
            //   console.warn('error: ', err.message);
            //   return of(false);
            // })
        )
    }

}
