// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: "https://back.glamy.es/",
  api: {
    iban: "iban",
    products: "products",
    users: "users",
    comments: "comments",
    auth: "auth",
    checkoutPage: "checkout",
    purchases: "purchases",
    sales: "sales",
    correos: "correos",
    notifications: "notifications",
    cart: "cart",
    coupons: "coupons",
    suborders: "suborders",
    comissions: "comissions",
  },
  version: "3.0.0",
  paypalClientId:
    "Afec2m8r4asVnhcVy_B8vUzArX5ef1Ob7yK78x8pERTjNj0bpgCdWEP16mu503tBQSSQrAeTnyeiwojD",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
