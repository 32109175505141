import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { Product, ProductItem, ProductFilter, ProductNew } from '../interfaces/product.interface';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private productsChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public productsChange$: Observable<boolean> = this.productsChangeSubject.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  getProduct(slug: string): Observable<Product> {
    const url = ENV.baseUrl + ENV.api.products + '/' + encodeURIComponent(slug);
    return this.http.get<Product>(url);
  }

  getStoreProducts(
    userId: number,
    pageIndex: number = 1,
    pageSize: number = 25,
    filter?: string ,
    sortField: string = 'created_at',
    sortOrder: 'asc' | 'desc' = 'desc',
    ): Observable<any> {

    const url = ENV.baseUrl + ENV.api.products;

    let params = new HttpParams()
    .append('seller_id', `${userId}`)
    .append('page', `${pageIndex}`)
    .append('limit', `${pageSize}`)
    .append('orderBy', `${sortField}`)
    .append('orderType', `${sortOrder}`);

    if (filter) {
      params = params.append('textFilter', `${filter}`);
    }

    return this.http.get<any>(url, { params, observe: 'response' });
  }

  getProducts(
    pageIndex: number = 1,
    filters?: ProductFilter,
    pageSize: number = 25,
    sortField: string = 'created_at',
    sortOrder: 'asc' | 'desc' = 'desc'
  ): Observable<HttpResponse<ProductItem[]>> {

    const url = ENV.baseUrl + ENV.api.products;

    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('limit', `${pageSize}`)
      .append('orderBy', `${sortField}`)
      .append('orderType', `${sortOrder}`);

    if (filters) {
      const filtersKeys = Object.keys(filters);
      filtersKeys.forEach(key => {
        let value;
        if (key === 'textFilter') {
          // value = encodeURIComponent(filters[key]); // No es necesario hacer "encodeURI" si se añade como HttpParams
          value = filters[key];
        } else if (key === 'onDepositFilter'){
          value = filters[key];
        } else {
          value = filters[key].id;
        }
        params = params.append(key, value);
      });
    }

    return this.http.get<ProductItem[]>(url, { params, observe: 'response' });
  }

  getProductsByOwner(
    ownerName: string,
    pageIndex: number = 1,
    pageSize: number = 50
  ): Observable<HttpResponse<{ id?: any; slug?: any; main_image?: { url_medium?: any; url_small?: any; }; status_id?: any; on_deposit?: any }[]>> {
    const url = ENV.baseUrl + ENV.api.products + '/user/' + encodeURIComponent(ownerName);
    const params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('limit', `${pageSize}`);
    return this.http.get<any[]>(url, { params, observe: 'response' });
  }

  getUserProducts(
    type: 'like' | 'comment' | 'fav',
    pageIndex: number = 1,
    pageSize: number = 25
  ): Observable<HttpResponse<ProductItem[]>> {

    const url = ENV.baseUrl + ENV.api.products + '/gallery/' + type;

    const params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('limit', `${pageSize}`);

    return this.http.get<ProductItem[]>(url, { params, observe: 'response' });
  }

  getShowcase(
    pageIndex: number = 1,
    pageSize: number = 25,
    sortField: string = 'created_at',
    sortOrder: 'asc' | 'desc' = 'desc'
  ): Observable<HttpResponse<{ index: number; product: ProductItem; }[]>> {
    const url = ENV.baseUrl + ENV.api.products + '/showcase';

    const params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('limit', `${pageSize}`)
      .append('orderBy', `${sortField}`)
      .append('orderType', `${sortOrder}`);

    return this.http.get<{ index: number; product: ProductItem; }[]>(url, { params, observe: 'response' });
  }

  removeProduct(productId) {
    const url = ENV.baseUrl + ENV.api.products + '/remove';
    return this.http.post<any>(url, { productId }).pipe(tap(() => {
      this.productsChangeSubject.next(true);
    }));
  }

  newProduct(formProduct: ProductNew, photos: string[]) {
    const url = ENV.baseUrl + ENV.api.products + '/new';

    const formData = new FormData();
    let index = 0;
    const prefixName = formProduct.name ? formProduct.name : 'photo';
    for (const i of photos) {
      if (i) {
        formData.append('images', this.b64ToBlob(i), `${prefixName + index++}`);
      }
    }
    const keysForm = [...Object.keys(formProduct)];
    for (const k of keysForm) {
      if (k === 'categories') {
        formData.append(`category1`, formProduct[k].leaf);
      } else if (k === 'colors') {
        formData.append(`color`, formProduct[k]);
      } else {
        formData.append(`${k}`, formProduct[k]);
      }
    }

    return this.http.post<any>(url, formData).pipe(tap(() => {
      this.productsChangeSubject.next(true);
    }));
  }

  updateProduct(productId: any, formProduct: ProductNew, photos: string[]) {
    const url = ENV.baseUrl + ENV.api.products + '/' + productId;

    const formData = new FormData();
    let index = 0;
    const prefixName = formProduct.name ? formProduct.name : 'photo';
    for (const i of photos) {
      if (i) {
        if (i.startsWith('http')) {
          formData.append('changesImages', i);
        } else {
          formData.append('changesImages', 'new');
          formData.append('images', this.b64ToBlob(i), `${prefixName + index++}`);
        }
      }
    }
    const keysForm = [...Object.keys(formProduct)];
    for (const k of keysForm) {
      if (k === 'categories') {
        formData.append(`category1`, formProduct[k].leaf);
      } else if (k === 'colors') {
        formData.append(`color`, formProduct[k]);
      } else {
        formData.append(`${k}`, formProduct[k]);
      }
    }

    return this.http.put<any>(url, formData);
  }

  private b64ToBlob(b64Img: string): Blob {
    const rawData = atob(b64Img);
    const bytes = new Array(rawData.length);
    for (let x = 0; x < rawData.length; x++) {
        bytes[x] = rawData.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    const blob = new Blob([arr], {type: 'image/png'});
    return blob;
  }

  toggleFav(id: any) {
    const url = ENV.baseUrl + ENV.api.products + '/' + id + '/fav';
    return this.http.post<any>(url, {});
  }

  toggleLike(productId: any) {
    const url = ENV.baseUrl + ENV.api.products + '/likes';
    return this.http.post<{ like: any; }>(url, { productId });
  }
}
