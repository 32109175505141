import { Injectable } from '@angular/core';
import { environment as ENV } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Notification } from '../interfaces/notification.interface';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Plugins, Capacitor } from '@capacitor/core';
const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private countSubject: Subject<number> = new Subject<number>()
  public count$: Observable<number> = this.countSubject.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  getShippingsPending(): Observable<{ id: any, created_at: string }[]> {
    const url = ENV.baseUrl + ENV.api.notifications + '/pending';
    return this.http.get<{ id: any, created_at: string }[]>(url);
  }

  getNotifications(): Observable<Notification[]> {
    const url = ENV.baseUrl + ENV.api.notifications;
    return this.http.get<Notification[]>(url);
  }

  public updateCount(total:number) {
    this.countSubject.next(total);
  }

  public resetCount() {
    if (Capacitor.platform !== 'web') {
      PushNotifications.removeAllDeliveredNotifications();
    }
    this.countSubject.next(0);
  }

  getUnreadNotifications(): Observable<number>{
    const url = ENV.baseUrl + ENV.api.notifications + '/total';
    return this.http.get<any>(url).pipe(map(
      (response) => {
        return response.unreadNotifications + response.pendingShippings;
      } 
    ));
  }
}
