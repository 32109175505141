import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {

  private previousUrl: string;
  private currentUrl: string;
  private history: string[] = [];

  constructor(private router: Router) {
    console.log('CONSTRUCTOR ---> PREVIOUSROUTESERVICE');
    this.currentUrl = this.router.url;
    this.previousUrl = null;

    this.router.events
                    .pipe(filter((event: any) => event instanceof RoutesRecognized), pairwise())
                    .subscribe((events: RoutesRecognized[]) => {
                      this.previousUrl = events[0].urlAfterRedirects;
                      this.currentUrl = events[1].urlAfterRedirects;

                      if (this.history && this.history.length > 0) {
                        const last = this.history[(this.history.length - 1)];
                        if (
                          '/escaparate' === this.currentUrl ||
                          '/perfil' === this.currentUrl ||
                          '/notificaciones' === this.currentUrl ||
                          '/subirproducto' === this.currentUrl ||
                          '/favoritos' === this.currentUrl
                        ) {
                          this.history = [];
                        } else if (last === this.currentUrl) {
                          this.history.pop();
                        } else {
                          this.history.push(events[0].urlAfterRedirects);
                        }
                      } else if ('/escaparate' === this.currentUrl) {
                        this.history = [];
                      } else if ('/escaparate' !== this.previousUrl) {
                        this.history = [this.previousUrl];
                      }
                    });
  }

  getPreviousUrl() {
    if (this.history && this.history.length > 0) {
      return this.history[(this.history.length - 1)];
    } else {
      return null;
    }
  }
}
