import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CouponValidInterface, CouponValidResponse } from 'src/app/interfaces/coupon.interface';

@Component({
  selector: 'app-valid-coupon',
  templateUrl: './valid-coupon.component.html',
  styleUrls: ['./valid-coupon.component.scss'],
})
export class ValidCouponComponent implements OnInit {

  @Input() validCoupon: CouponValidResponse;
  @Input() sold: boolean;
  @Output() removeValidCouponFromList  = new EventEmitter<CouponValidResponse>()


  constructor() { }

  ngOnInit() {}

  couponTypeLabel(iCoupon: CouponValidInterface): string{

    if(iCoupon.cmd){
      if(iCoupon.criteria === 1){
        return iCoupon.cmd + '€ descuento por compra';
      } else {
        return iCoupon.cmd + '€ descuento por prenda';
      } 
    }else{
      if(iCoupon.criteria === 1){
        return iCoupon.quantity + '% descuento por compra';
      } else {
        return iCoupon.quantity  + '% descuento por prenda';
      } 
    }
  }

  couponTotalDiscount(couponValid: CouponValidResponse){

    let totalCouponDiscount = 0;
  
    if(couponValid.productsInCartAssociated){

      if(couponValid.coupon.criteria === 2){
    
        couponValid.productsInCartAssociated.forEach(product => {
          
          if(couponValid.coupon.cmd){
    
            if(parseFloat(couponValid.coupon.cmd.toString()) >= parseFloat(product.product.sale_price)){
              totalCouponDiscount += parseFloat(product.product.sale_price) ;
            } else {
              totalCouponDiscount += parseFloat(couponValid.coupon.cmd.toString()) ;
            }
    
          }else{
            totalCouponDiscount += parseFloat(product.product.sale_price)*(couponValid.coupon.quantity/100);
          }
    
        });
    
      }else{
        let totalCouponProductsPrice=0;
    
        couponValid.productsInCartAssociated.forEach(product => {
          totalCouponProductsPrice += parseFloat(product.product.sale_price);
        });
    
        if(couponValid.coupon.cmd){
    
          if(parseFloat(couponValid.coupon.cmd.toString()) >= totalCouponProductsPrice){
            totalCouponDiscount = totalCouponProductsPrice ;
          } else {
            totalCouponDiscount = parseFloat(couponValid.coupon.cmd.toString()) ;
          }
    
        }else{
          totalCouponDiscount = totalCouponProductsPrice*(couponValid.coupon.quantity/100);
        }
    
      }
    }
    couponValid.couponDiscount = totalCouponDiscount;
  
    return couponValid.couponDiscount;
  }

  removeValidCoupon(){
    this.removeValidCouponFromList.emit(this.validCoupon);
  }

}
