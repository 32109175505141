import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'counter'
})
export class CounterPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let res = value;
    if (!isNaN(value)) {
      if (value > 1000000) {
        res = Math.floor(value / 1000000);
        res += 'M';
      } else if (value > 1000) {
        res = Math.floor(value / 1000);
        res += 'K';
      }
    }
    return res;
  }

}
