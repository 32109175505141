import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Address } from '../../interfaces/address.interface';
import { UserService } from '../../services/user.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-address-input-dumb',
  templateUrl: './address-input-dumb.component.html',
  styleUrls: ['./address-input-dumb.component.scss'],
})
export class AddressInputDumbComponent implements OnChanges{

  @Input() title = '';
  @Input() address: Address;
  editMode: boolean ;
  invalidPostalCodes = [];
  addressForm: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private userSvc: UserService,
    private toastCtrl: ToastController
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.address  && this.address.id) {
      this.editMode = false;
    } else {
      this.address = {};
      this.editMode = true;
    }
    this.loadForm();
  }

  changeEditMode(newEditMode: boolean) {
    if (!newEditMode) {
      this.loadForm();
    }
    this.editMode = newEditMode;
  }

  onSubmit() {
    this.userSvc.setUserAddress(this.addressForm.value).subscribe(
      newAddress => {
        console.log(newAddress);
        this.address = { ...newAddress };
        this.changeEditMode(false);
        this.presentToast('Se guardaron los datos de la dirección', 'primary', 3000);
      },
      e => {
        this.submitted = true;
        console.log('Error: ', e);
        if (e && e.status === 409) {
          if (e.error && e.error.data) {
            this.invalidPostalCodes.push(e.error.data.postal_code);
          }
          this.presentToast('Código postal inválido', 'warning', 4000);
        } else {
          this.presentToast('Se ha producido un error en el envío de la dirección', 'warning', 4000);
        }
      }
    );
  }

  private loadForm() {
    this.addressForm = this.fb.group({
      address: [this.address.address, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      city: [this.address.city, [Validators.required, Validators.minLength(3), Validators.maxLength(80)]],
      province: [this.address.province, [Validators.required, Validators.minLength(3), Validators.maxLength(80)]],
      country: [{value: 'España', disabled: true}, [Validators.required, Validators.minLength(2), Validators.maxLength(80)]],
      phone: [this.address.phone, [Validators.required, Validators.minLength(9), Validators.maxLength(50)]],
      postal_code: [
        this.address.postal_code,
        [ Validators.required, Validators.minLength(3), Validators.maxLength(5), this.validPostalCode(this.invalidPostalCodes)]],
      contact: [this.address.contact, [Validators.required]]
    });
  }

  private validPostalCode(invalidCodes: any[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const value = control.value;
      if (invalidCodes.includes(value)) {
        return {invalidCode: {value: true}};
      }
      return null;
    };
  }

  private async presentToast(message, color: 'primary' | 'warning' = 'primary', duration = 2000) {
    const toast = await this.toastCtrl.create({
        message,
        duration,
        color,
        position: 'middle'
    });
    toast.present();
}
}
