export class Follower {
    username: string;
    url_medium: string;
    url_small: string;
    total_products: number;

     constructor(followerJSON: any) {
        this.username = followerJSON.username;
        this.url_medium = (followerJSON.detail && followerJSON.detail.avatar)? followerJSON.detail.avatar.url_medium : '/assets/img/user.svg';
        this.url_small = (followerJSON.detail && followerJSON.detail.avatar)? followerJSON.detail.avatar.url_small : '/assets/img/user.svg';
        this.total_products = followerJSON.total_products;
    }
}


export interface FollowerJSON {
    id?: any,
    username?: string,
    total_products?: number,
    created_at?: string,
    detail?: {
            user_id?: any,
            avatar?: {
                    url_medium?: string,
                    url_small?: string
                    }
            }
}

export class FollowerList {
    followers: Follower[];
    lastPage: boolean;
    
    constructor(followers: Follower[] , lastPage: boolean){
        this.followers=followers;
        this.lastPage=lastPage;
    }
}