import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, from } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private refreshingInProgress = false;
    private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private authService: AuthenticationService,
        private router: Router
        ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('/auth')) {
            return next.handle(req);
        }

        const obsToken = from(this.authService.userLoggedWithValidToken('token'));
        const obsRefreshToken = from(this.authService.userLoggedWithValidToken('refreshToken'));

        let accessToken;
        let refreshToken;

        return obsToken.pipe(
            switchMap((accessTokn: string) => {
                accessToken = accessTokn;
                if (accessTokn) {
                    return next.handle(this.addAuthorizationHeader(req, accessTokn));
                } else {
                    return obsRefreshToken.pipe(
                        switchMap( token => {
                            refreshToken = token;
                            if (refreshToken) {
                                return this.refreshToken(req, next);
                            } else {
                                    return next.handle(req);
                            }
                        })
                    );
                }
            }),
            catchError(err => {
                // in case of 403 http error --> try refresh token
                if (err instanceof HttpErrorResponse && err.status === 403) {
                    // if there are tokens then send refresh token request
                    return obsRefreshToken.pipe(
                        switchMap( token => {
                            refreshToken = token;
                            if (refreshToken) {
                                return this.refreshToken(req, next);
                            } else {
                                // otherwise logout and redirect to login page
                                return this.logoutAndRedirect(err);
                            }
                        })
                    );
                }

                // in case of 401 http error (refresh token failed) --> logout and clear storage
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    // logout and redirect to login page
                    return this.logoutAndRedirect(err);
                }

                // if error has status neither 401 nor 403 then just return this error
                return throwError(err);
            })
        );
    }

    private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        if (token) {
            return request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
        }

        return request;
    }

    private logoutAndRedirect(err): Observable<HttpEvent<any>> {
        this.authService.logout().then(() => {
            console.log('CLOSED SESSION OK');
        })
        .catch(err => {
            console.log('ERROR CLOSING SESSION --> ', err);
        });

        return throwError(err);
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.refreshingInProgress) {
            this.refreshingInProgress = true;
            this.accessTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                finalize(() => {
                    this.refreshingInProgress = false;
                }),
                switchMap((res) => {
                    this.refreshingInProgress = false;
                    this.accessTokenSubject.next(res.accessToken);
                    // repeat failed request with new token
                    return next.handle(this.addAuthorizationHeader(request, res.accessToken));
                })
            );
        } else {
            // wait while getting new token
            return this.accessTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    // repeat failed request with new token
                    return next.handle(this.addAuthorizationHeader(request, token));
                }));
        }
    }
}
