import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, ModalController } from '@ionic/angular';
import { PreviousRouteService } from '../../services/previous-route.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  @Input() title = '';
  @Input() isPremier = false;
  @Input() isModal = false;

  readonly premierIcon = './assets/img/premierWhite.svg';

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private previousRouteService: PreviousRouteService
  ) { }

  goToMyCart() {
    // this.navCtrl.navigateForward('/micarrito');
    this.router.navigate(['/micarrito']);
  }

  myBackButton() {
    const prevUrl = this.previousRouteService.getPreviousUrl();
    if (prevUrl) {
      this.router.navigate([prevUrl]);
    } else {
      this.router.navigate(['/']);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
